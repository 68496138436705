<template>
    <template v-if="global.entity.user.checkRight(getPrimary())">
        <div class="list-wrapper-header">
            <h4>Список лицензий</h4>
            <el-button class="add" @click="$router.push('/license/'+$route.params.city_id+'/add')"  v-if="global.entity.user.checkRight(getMainAdd())">Добавить лицензию</el-button>
        </div>

        <vdg :name="name" />
    </template>
    <NotRight v-else />
</template>

<script>
import { inject } from "vue";
import headerJson from './header.json';
import { useRouter, useRoute } from "vue-router";

export default {
    name: "LicenseList",
    props: ['city_id'],
    setup(){
        const global = inject('global')

        const router = useRouter()
        const route = useRoute()

        const rightForCity = global.entity.user.rightForCity.filter(el => el.cities_id == route.params.city_id);
        const name = 'license_' + route.params.city_id;

        const content = {
            action : {
                fetch : global.entity.grid.fetch(global),
                initBeforeHeader : (grid) => {
                    /*let obj = {
                        37 : 'name',
                    }
                    for(let key in obj) {
                        if (global.checkRight(key))
                            grid.header[obj[key]].exclude = false;
                        else
                            grid.header[obj[key]].exclude = true;
                    }*/
                }
            },
            router : router,
            route : route,
            header : headerJson,
            filter : {
                "id" : {
                    "type" : "number",
                    "name" : "Номер"
                },
                "owner" : {
                    "type" : "searchList",
                    "name" : "Собственник",
                    "url"  : "http://94.125.52.66:8000/api/license/"+route.params.city_id+"/search/owner",
                    "multiple" : false
                },
                "auto" : {
                    "type" : "searchList",
                    "name" : "Авто",
                    "url"  : "http://94.125.52.66:8000/api/license/"+route.params.city_id+"/search/auto",
                    "multiple" : false
                },
                "auto_number" : {
                    "type" : "searchList",
                    "name" : "Гос номер",
                    "url"  : "http://94.125.52.66:8000/api/license/"+route.params.city_id+"/search/auto_number",
                    "multiple" : false
                },
                "phone" : {
                    "type" : "searchList",
                    "name" : "Телефон",
                    "url"  : "http://94.125.52.66:8000/api/user/search-phone",
                    "meta" : {
                        "mask" : "+7 (###) ###-##-##"
                    },
                    "multiple" : true
                },
                "active" : {
                    "type" : "list",
                    "name" : "Активность",
                    "option" : [
                        {"value" :  "Активный", "key":  "1","selected" : false},
                        {"value" :  "Не активный", "key":  "0","selected" : false}
                    ]
                }
            },
            pagination : global.entity.grid.pagination(),
            config : global.entity.grid.config(global,'/license/'+route.params.city_id),
            rowContext : {},
        };

        global.entity.grid.init(name, content);
        return {global, name, rightForCity}
    },
    methods : {
        getPrimary : function(){
            return this.rightForCity.filter(el => el.type = 'primary')[0].rights_id;
        },
        getMainAdd : function(){
            return this.rightForCity.filter(el => el.type = 'main').filter(el => el.event = 'add')[0].rights_id;
        }
    }
}
</script>
