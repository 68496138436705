<template>
    <template v-if="global.entity.user.checkRight(36)">
        <div class="list-wrapper-header">
            <h4>Список пользователей</h4>
            <el-button class="add" @click="$router.push('/user/add')"  v-if="global.entity.user.checkRight(46)">Добавить пользователя</el-button>
        </div>

        <vdg :name="name" />
    </template>
    <NotRight v-else />
</template>

<script>
import { inject } from "vue";
import headerJson from './header.json'
import filterJson from "./filter.json";
import { useRouter, useRoute } from "vue-router";

export default {
    name: "UserList",
    setup(){
        const global = inject('global')

        const name = 'users';
        const router = useRouter()
        const route = useRoute()

        const content = {
            action : {
                fetch : global.entity.grid.fetch(global),
                initBeforeHeader : (grid) => {
                    let obj = {
                        37 : 'name',
                        38 : 'login',
                        39 : 'phone',
                        40 : 'email',
                        41 : 'position',
                        42 : 'group',
                        43 : 'right',
                        44 : 'city',
                        45 : 'active',
                    }
                    for(let key in obj)
                        grid.header[obj[key]].exclude = !global.entity.user.checkRight(key);
                }
            },
            router : router,
            route : route,
            header : headerJson,
            filter : global.entity.grid.filterUpdate(filterJson),
            pagination : global.entity.grid.pagination(),
            config : global.entity.grid.config(global,'/user'),
            rowContext : {
                ...global.entity.grid.context({
                    ctx: global,
                    entity: 'user',
                    edit: true,
                }),
                right: {
                    text: 'Настроить права',
                    function: function ({row, grid}) {
                        router.push('/user/' + row.id[0].value + '/right')
                    }
                },
                group: {
                    text: 'Настроить группы',
                    function: function ({row, grid}) {
                        router.push('/user/' + row.id[0].value + '/group')
                    }
                },
                ...global.entity.grid.context({
                    ctx: global,
                    entity: 'user',
                    deactivation: true,
                    activation: true,
                    remove: true,
                })
            }
        };

        global.entity.grid.init(name, content);
        return {global, name}
    }
}
</script>
