<template>
    <template v-if="global.entity.user.checkRight(1)">
        <div class="list-wrapper-header">
            <h4>Города</h4>
            <el-button class="add" @click="$router.push('/city/add')" v-if="global.entity.user.checkRight(3)">Добавить город</el-button>
            <el-button v-if="!editable && global.entity.user.checkRight(4)" class="edit" @click="edit">Редактировать</el-button>
            <el-button v-if="editable" type="success" icon="Check" class="save" @click="save">Сохранить</el-button>
            <el-button v-if="editable" type="danger" icon="Close" class="cancel" @click="cancel">Отменить</el-button>
        </div>

        <vdg :name="name"/>
    </template>
    <NotRight v-else />
</template>
<script>
import headerJson from './header.json'
import filterJson from "./filter.json";
import {inject} from "vue";
import {useRoute, useRouter} from "vue-router";



export default {
    name: "CityList",
    setup(){
        const global = inject('global');

        const name = 'city';

        const content = {
            action : {
                fetch : global.entity.grid.fetch(global),
                initBeforeHeader : (grid) => {
                    if(global.entity.user.checkRight(2))
                        grid.header.active.exclude = false;
                    else
                        grid.header.active.exclude = true;
                }
            },
            header : headerJson,
            filter : global.entity.grid.filterUpdate(filterJson),
            router : useRouter(),
            route : useRoute(),
            pagination : global.entity.grid.pagination(),
            config : global.entity.grid.config(global,'/city'),
            rowContext : {
                ...global.entity.grid.context({
                    ctx : global,
                    entity : 'city',
                    deactivation : true,
                    activation : true,
                    remove : true,
                }),
            },
            wrapperCell : {
                ...global.entity.grid.cell({
                    radio : true,
                    string : true
                }),
            }
        };

        const grid = global.entity.grid.init(name, content);

        return {name, global, grid}
    },
    data : function(){
        return {
            editable : false,
            key : 0
        }
    },
    methods : {
        edit : function(){
            this.editable = true;
            this.global.entity.grid.editable = true;
            this.grid.$action.extReload(this.grid);
        },
        save : function(){
            this.editable = false;
            this.global.entity.grid.editable = false;
            let elements = [];

            this.grid.elements.forEach(element => {
                elements.push({
                    id : element.id[0].value,
                    name : element.name[0].value,
                    active : element.active[0].value
                })
            })

            this.grid.$action.fetch(this.grid, this.global.entity.site.domain + '/city/updates',{
                elements : elements
            }).then(res => {
                if(res.user.authorize)
                    this.grid.$action.extReload(this.grid);
            }).catch(() => {
                this.global.notify.error();
            })
        },
        cancel : function(){
            this.editable = false;
            this.global.entity.grid.editable = false;
            this.grid.$action.extReload(this.grid);
        }
    }
}
</script>
