<script>
import {h, inject, resolveComponent} from "vue";

export default {
    name: "NavBar",
    setup() {
        let global = inject('global')
        return {global}
    },
    computed : {
        menu : function(){
            function pack (array){
                let key = array[0].key,lvl =  array[0].lvl
                if(lvl > 1) {
                    let child = array.filter(el => {
                        if (key == el.key && lvl == el.lvl)
                            return el
                    }).sort((a,b) => b.sort - a.sort)

                    array = array.filter(el => {
                        return key == el.key && lvl == el.lvl ? false : true
                    })

                    array.forEach(el => {
                        if (key == el.key && lvl-1 == el.lvl)
                            el.child = child
                    })

                    array = pack(array)
                }

                return array.sort((a,b) => b.sort - a.sort)

            }
            return pack(
                this.$router.getRoutes().filter(el => el.meta.navbar)
                    .filter(el => {
                        if('right' in el.meta){
                            return this.global.entity.user.checkRight(el.meta.right)
                        }else
                            return true
                    })
                    .sort((a,b) => b.meta.lvl - a.meta.lvl)
                    .map(el => {
                        return {
                            path    : el.path,
                            name    : el.meta.name,
                            sort    : el.meta.sort,
                            lvl     : el.meta.lvl,
                            key     : el.meta.key,
                            child   : []
                        }
                    })
            )
        },
        activeIndex : function(){
            return this.$route.path;
        }
    },
    render(){
        let item = this.extract(this.menu);

        if(!this.global.entity.user.authorized)
            item.push(h(resolveComponent('el-menu-item'),{
                class : ['auth-item'],
                index : '',
                onClick : () => {
                    this.global.bridgeComponentData.NavBarToAuthorize.showWindow = true;
                }
            }, () => "Авторизация"))
        else{

            if(this.global.entity.user.accessCity.length)
                item.push(
                    h(resolveComponent('el-sub-menu'),{index : 'license'}, {
                            default: () => this.global.entity.user.accessCity.map(el => {
                                return h(resolveComponent('el-menu-item'),{
                                    index : '/license/' + el.id,
                                    onClick : () => {
                                        this.$router.push('/license/' + el.id)
                                    }
                                },() => el.name)
                            }),
                            title: () => h('span', 'Лицензии')
                        }
                    )
                )


            item.push(
                h(resolveComponent('el-dropdown'),{
                        class : ['drop-end'],
                        placement : 'bottom',
                        teleported : false,
                        popperClass : 'popper-profile'
                    },{
                        default : () => h('div',{class : ['menuUser']},
                            [
                                h(resolveComponent('el-avatar'),{size : 38, src : this.global.entity.user.avatar}),
                                h('span',this.global.entity.user.name),
                                h('span',this.global.entity.user.position)
                            ]
                        ),
                        dropdown : () => [
                                h(resolveComponent('el-dropdown-menu'),{}, () => [
                                    h(resolveComponent('el-dropdown-item'),{onClick : () => this.$router.push('/user/profile')},() => "Профиль"),
                                    h(resolveComponent('el-dropdown-item'),{onClick : () => {
                                            this.global.fetch('/logout').then(res => res.json()).then(res => {
                                                if(res.status == 'success')
                                                    this.global.auth.confirm = false
                                            }).catch(() => {
                                                this.global.errNotify();
                                            })
                                        }},() => "Выход")
                            ])
                        ]
                    }

                )
            )
        }


        return h(
            resolveComponent('el-menu'),
            {
                defaultActive : this.$route.path,
                mode : 'horizontal',
                router : true
            },
            () => item
        );
    },
    methods : {
        extract(menu){
            return menu.map(el => {
                let component = 'el-menu-item',
                    child = () => el.key == 'home' ? h('span',h(resolveComponent('el-icon'), {}, () => h(resolveComponent('HomeFilled')))) : el.name
                if(el.child.length > 0) {
                    component = 'el-sub-menu'
                    child = {
                        title : () => h(
                            "span",
                            {
                                onClick : () => {
                                    this.$router.push(el.path)
                                }
                            },
                            el.name
                        ),
                        default : () => this.extract(el.child)
                    }
                }

                return h(
                    resolveComponent(component),
                    {
                        class : [{'is-active' : (this.$route.path == el.path && component == 'el-sub-menu' || this.$route.meta.key == el.key)}, "navbar"],
                        index : el.path
                    },
                    child
                )
            })
        }
    }
}
</script>
