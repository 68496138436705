<template>
    <div v-if="shown" class="install_app">
        Установите наше приложение
        <el-button-group>
            <el-button type="success" plain @click="installPWA">
                Установить
            </el-button>

            <el-button type="danger" plain @click="dismissPrompt">
                Нет спасибо
            </el-button>
        </el-button-group>
    </div>
</template>

<script>
export default {
    data: () => ({
        shown: false,
    }),

    beforeMount() {
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault()
            this.installEvent = e
            this.shown = true
        })
    },

    methods: {
        dismissPrompt() {
            this.shown = false
        },

        installPWA() {
            this.installEvent.prompt()
            this.installEvent.userChoice.then((choice) => {
                this.dismissPrompt() // Hide the prompt once the user's clicked
                if (choice.outcome === 'accepted') {
                    // Do something additional if the user chose to install
                } else {
                    // Do something additional if the user declined
                }
            })
        },
    }
}
</script>
