<template>
    <template v-if="global.entity.user.checkRight(getMainAdd())">
        <div class="list-wrapper-header">
            <h4>Добавить авто</h4>
        </div>
        <div class="form-wrapper">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                v-loading="loading"
                label-width="120px"
                status-icon
                label-position="top"
            >
                <el-form-item label="Собственник" prop="owner">
                    <el-input v-model="form.owner" />
                </el-form-item>

                <template v-for="(item,index) in form.phone" :key="item.key">
                    <el-form-item label="Телефон" v-if="index == 0">
                        <el-input @blur="blurPhone" placeholder="+7 (###) ###-##-##" v-maska="'+7 (###) ###-##-##'" v-model="item.value" />
                    </el-form-item>
                    <el-form-item class="field-phone-number" v-else>
                        <el-input @blur="blurPhone" placeholder="+7 (###) ###-##-##" v-maska="'+7 (###) ###-##-##'" v-model="item.value" />
                        <el-button @click="removePhone(item.key)" icon="Close"></el-button>
                    </el-form-item>
                    <el-form-item v-if="index == form.phone.length - 1">
                        <el-button @click="addPhone" icon="Check" class="button-add-number">Добавить телефон</el-button>
                    </el-form-item>
                </template>

                <el-form-item label="Авто" prop="auto">
                    <el-input v-model="form.auto" />
                </el-form-item>
                <el-form-item label="Цвет" prop="color">
                    <el-input v-model="form.color" />
                </el-form-item>
                <el-form-item label="Гос номер" prop="auto_number">
                    <el-input v-model="form.auto_number" />
                </el-form-item>
                <el-form-item label="Номер лицензии" prop="license_number">
                    <el-input v-model="form.license_number" />
                </el-form-item>
                <el-form-item label="Дата выдачи лицензии" prop="date_start">
                    <el-date-picker style="width: 100%" type="date" format='DD.MM.YYYY' valueFormat='DD.MM.YYYY' v-model="form.date_start" />
                </el-form-item>
                <el-form-item label="Дата передачи лицензии водителю" prop="to_driver">
                    <el-date-picker style="width: 100%" type="date" format='DD.MM.YYYY' valueFormat='DD.MM.YYYY' v-model="form.to_driver" />
                </el-form-item>
                <el-form-item>
                    <el-button class="form-button" type="primary" plain @click="add">Доавить</el-button>
                </el-form-item>
            </el-form>
        </div>
    </template>
    <NotRight v-else />
</template>
<script>

import {inject} from "vue";
import {useRoute} from "vue-router";

export default {
    name: "LicenseAdd",
    setup(){
        const global = inject('global')
        const route = useRoute()

        const rightForCity = global.entity.user.rightForCity.filter(el => el.cities_id == route.params.city_id);

        return {global, rightForCity}
    },
    data : function(){
        return {
            loading : false,
            form : {
                owner           : '',
                auto            : '',
                color           : '',
                auto_number     : '',
                license_number  : '',
                date_start      : '',
                to_driver       : '',
                phone           : [
                    {
                        key     : 1,
                        value   : "",
                    }
                ],
            },
            rules : {
                owner       : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                auto        : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                color       : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                auto_number : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}]
            }
        }
    },
    methods : {
        addPhone : function(){
            this.form.phone = this.form.phone.map((el,i) => {return {key : i, value : el.value}})
            this.form.phone.push({value: '', key : this.form.phone.length})
        },
        removePhone : function(key){
            this.form.phone = this.form.phone
                .filter(el => key != el.key).map((el,i) => {return {key : i, value : el.value}})
        },
        blurPhone : function(){
            this.form.phone = this.form.phone.map(el => {
                if(el.value.length < 18)
                    el.value = ''

                return el
            })
        },
        add : function(){
            this.$refs.form.validate((valid) => {
                if(valid) {
                    this.loading = true
                    this.global.fetch('/license/'+this.$route.params.city_id+'/add',{
                        ...this.form
                    }).then(res => res.json()).then(res => {
                        if(res.user.authorize){
                            this.global.notify.message({
                                title : "Успешно!",
                                text : "Авто успешно добавлен",
                                type : 'success'
                            });
                            this.$router.push('/license/'+this.$route.params.city_id)
                        }
                    }).catch(() => {
                        this.global.notify.error();
                    }).finally(() => this.loading = false)
                }
            })
        },
        getMainAdd : function(){
            return this.rightForCity.filter(el => el.type = 'main').filter(el => el.event = 'add')[0].rights_id;
        }
    }
}
</script>
