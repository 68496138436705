<script>
import {inject, h, resolveComponent, Transition, reactive, computed, Teleport} from "vue";
import { mask } from "maska"
import SearchList from "./searchList";

export default {
    name : 'Filterable',
    props : ['name'],
    components : { SearchList },
    setup(props){
        const grid = inject(props.name);
        return {grid}
    },
    computed : {
        filter : function() {
            return this.grid.filterProfile[this.grid.condition.filter.profile]
        },
        tags : function(){
            let arr =  this.filter.fields.filter(field => field.show).filter(field => {
                return this.checkField(field)
            }).map(field => {
                return this.queryTag(field)
            })

            let cut = arr.reduce((previousValue, currentValue,i) => {
                    let len = (currentValue.name + currentValue.value).length;

                    if((previousValue.len + len) < 90) {
                        previousValue.len = previousValue.len + len;
                        previousValue.key.push(currentValue.key);
                    }

                    return previousValue
                },
                {len : 0, key : []}
            )

            let tags = arr.filter(el => cut.key.includes(el.key));

            if(tags.length > 0 && arr.length > cut.key.length)
                tags.push({
                    key : 'all',
                    name : 'Еще',
                    value : arr.length - cut.key.length
                })
            else if(arr.length && (arr[0].name + arr[0].value).length > 90){
                tags.push({
                    key : arr[0].key,
                    name : arr[0].name,
                    value : arr[0].value
                })
            }

            if(!arr.length)
                this.grid.condition.filter.use = false

            return tags;
        }
    },
    data : function(){
        return {
            condition : {
                window : {
                    show : false,
                    expect : false,
                    hide : true
                },
                drop : {
                    top : 0,
                    left : 0,
                    show : false,
                    button : computed(() => !!this.filter.fields.filter(field => !field.show).length)
                },
                profile : {
                    creation : {
                        use : false,
                        form : {
                            name : ''
                        },
                        rules : {
                            name: [
                                { required: true, message: 'Укажите наименование профиля', trigger: 'blur' },
                                { min: 1, max: 30, message: 'Наименование профиля не более 30 символов', trigger: 'blur' },
                            ]
                        }
                    },
                    filled : computed(() => !!this.filter.fields.filter(field => this.checkField(field)).length),
                    rename : false
                }
            },
            tagsClone : []
        };
    },
    methods : {
        updateClone : function(key){
            this.grid.filterProfileClone[key] = JSON.parse(JSON.stringify(this.grid.filterProfile[key]))
        },
        open : function(){
            this.condition.window.show = true;
            document.addEventListener('click', this.close);
        },
        close : function($event) {
            if($event.target.closest('.vdg_filter.' + this.name) == null && !this.condition.window.expect) {
                this.condition.window.show = false;
                document.removeEventListener('click', this.close)
            }else if(this.condition.window.hide){
                this.condition.window.expect = false;
            }
        },
        clearField : function(key){
            this.filter.fields.filter(field => key == field.key).forEach(field => {
                switch (field.type){
                    case 'date': case 'number':
                        field.operation = '=';
                        field.min = '';
                        field.max = '';
                        break;
                    case 'searchList':
                        field.value = [];
                        break;
                    case 'list':
                        field.value = [];
                        break;
                }
            })
        },
        checkField : function(field){
            switch (field.type){
                case 'date':case 'number':
                    if(field.min || field.max)
                        return true
                    break;
                case 'list': case 'searchList':
                    if(field.value.length > 0)
                        return true
                    break;
                default: return false
            }
        },
        hideField : function(key){
            let element = this.filter.fields[key]
            return h( resolveComponent ('el-button'), {
                    class : ['el-button', 'el-button--default'],
                    onClick : () => {
                        this.clearField(element.key);
                        element.show = false;
                        this.condition.window.expect = true;
                        this.grid.$action.updateCondition(this.grid, 'hideField', element);
                    }
                },
                () => h(resolveComponent('el-icon'), () => h(resolveComponent('close')))
            )
        },
        number : function(key){
            let element = this.filter.fields[key];
            return [
                h('label',element.name),
                h('div', {class : 'vdg_filter_number'},
                    [
                        h( resolveComponent('el-select'), {
                                'modelValue' : element.operation,
                                'onUpdate:modelValue': value => {
                                    element.operation = value
                                    this.grid.$action.updateCondition(this.grid, 'changeValueFilterField');
                                },
                            },
                            () => element.operations.map(el => {
                                return h(resolveComponent('el-option'), {label : el.label, value : el.value}, '')
                            })
                        ),
                        h( resolveComponent('el-input'), {
                                'modelValue' : element.min,
                                'onUpdate:modelValue': value => {
                                    if(value == "")
                                        element.min = ""
                                    else
                                        element.min = Number(value) ? +value : +element.min;

                                    this.grid.$action.updateCondition(this.grid, 'changeValueFilterField');
                                },
                                onBlur : function(){
                                    if(element.min > element.max && element.max) element.max = +element.min + 1;
                                },
                                placeholder : 'Введите значение'
                            }
                        ),
                        element.operation == '><' ? h(
                            resolveComponent('el-input'), {
                                'modelValue' : element.max,
                                'onUpdate:modelValue': value => {
                                    if(value == "")
                                        element.max = ""
                                    else
                                        element.max = Number(value) ? +value : element.max;

                                    this.grid.$action.updateCondition(this.grid, 'changeValueFilterField');
                                },
                                onBlur : function(){
                                    if(Number(element.min) > Number(element.max) && element.max) element.max = +element.min + 1;
                                },
                                placeholder : 'Введите значение'
                            }
                        ) : null,
                        this.hideField.call(this,key)
                    ]
                ),
            ]
        },
        date : function(key){
            let element = this.filter.fields[key];
            let setting = {
                type : 'date',
                format : 'DD.MM.YYYY',
                valueFormat : 'DD.MM.YYYY',
                placeholder : 'Укажите дату',
                'onVisibleChange' : v => { this.condition.window.hide = !v;this.condition.window.expect = v; }
            };
            return [
                h('label',element.name),
                h('div', {class : 'vdg_filter_date'},
                    [
                        h( resolveComponent('el-select'), {
                                'modelValue' : element.operation,
                                'onUpdate:modelValue': value => {
                                    element.operation = value
                                    this.grid.$action.updateCondition(this.grid, 'changeValueFilterField');
                                }
                            },
                            () => element.operations.map(el => {return h(resolveComponent('el-option'), {label : el.label, value : el.value}, '')})
                        ),
                        h( resolveComponent('el-date-picker'), {
                                ...setting,
                                'modelValue' : element.min,
                                'onUpdate:modelValue': value => {
                                    element.min = value
                                    this.grid.$action.updateCondition(this.grid, 'changeValueFilterField');
                                }
                            }
                        ),
                        element.operation == '><' ? h( resolveComponent('el-date-picker'), {
                                ...setting,
                                'modelValue' : element.max,
                                'onUpdate:modelValue': value => {
                                    if(value){
                                        let valueDate = value.split("."),
                                            valueTimestamp = new Date( valueDate[2], valueDate[1] - 1, valueDate[0]).getTime(),
                                            minDate = element.min.split("."),
                                            minTimestamp = new Date( minDate[2], minDate[1] - 1, minDate[0]).getTime();
                                        if(valueTimestamp < minTimestamp)
                                            element.max = element.min
                                        else element.max = value
                                    }else element.max = value
                                    this.grid.$action.updateCondition(this.grid, 'changeValueFilterField');
                                },
                            }
                        ) : null,
                        this.hideField.call(this,key)
                    ]
                ),
            ]
        },
        list : function(key){
            let element = this.filter.fields[key];
            return [
                h('label',element.name),
                h('div', {class : 'vdg_filter_list'},
                    [
                        h(resolveComponent('searchList'),{
                                multiple : element.multiple,
                                load : element.load,
                                list : element.option,
                                fetch : ({ctx}) => {
                                    return new Promise((res,rej) => {
                                        this.grid.$action.fetch(this.grid, element.url ,{searchQuery : ctx.query})
                                            .then(r => res(r.option))
                                    })
                                },
                                input : function(value){
                                    if("mask" in element.meta){
                                        this.query = mask(value, element.meta.mask)
                                        let symbol = this.query[this.query.length - 1];
                                        if(symbol == '-' || symbol == ' ' || symbol == ')')
                                            this.query = this.query.slice(0,-1)
                                    }else
                                        this.query = value;

                                    return this.query
                                },
                                onChange : () => {
                                    this.grid.$action.updateCondition(this.grid, 'changeValueFilterField');
                                },
                                tags : element.value
                            }
                        ),
                        this.hideField.call(this,key)
                    ]
                ),
            ]
        },
        searchList : function(key){
            let element = this.filter.fields[key];
            return [
                h('label',element.name),
                h('div', {class : 'vdg_filter_searchList'}, [
                    h(resolveComponent('searchList'),{
                            multiple : element.multiple,
                            fetch : ({ctx}) => {
                                return new Promise((res,rej) => {
                                    this.grid.$action.fetch(this.grid, element.url ,{searchQuery : ctx.query})
                                        .then(r => res(r.option))
                                })
                            },
                            input : function(value){
                                if("mask" in element.meta){
                                    this.query = mask(value, element.meta.mask)
                                    let symbol = this.query[this.query.length - 1];
                                    if(symbol == '-' || symbol == ' ' || symbol == ')')
                                        this.query = this.query.slice(0,-1)
                                }else
                                    this.query = value;

                                return this.query
                            },
                            onChange : () => {
                                this.grid.$action.updateCondition(this.grid, 'changeValueFilterField');
                            },
                            tags : element.value
                        }
                    ),
                    this.hideField.call(this,key)
                ]),
            ]
        },
        remote : function(element,query = ''){
            if(!element.loading) {
                element.query = query;
                element.loading = true;
                setTimeout((localQuery) => {
                    if (element.query == localQuery){
                        if(element.query != '' && element.query.length >= 3) {
                            this.grid.$action.fetch(this.grid, element.url, {searchQuery: query}).then(res => {
                                element.option = [];
                                element.option = res.option;
                                if ('changeOption' in element) {
                                    let option = element.changeOption.map(el => el.value)
                                    element.option = element.option.filter(el => !option.includes(el.value));
                                    element.option = element.option.concat(element.changeOption)
                                }
                            }).catch(() => {
                                element.loadingText = element.loadingError
                            }).finally(() => {
                                element.loading = false;
                            })
                        }else{
                            element.option = [];
                            element.loading = false;
                        }
                    }
                },600,query)
            }
        },
        queryTag : function(field){
            let value;
            switch (field.type){
                case 'date': case 'number':
                    value = field.operation + ' ' + field.min;
                    if(field.operation == '><')
                        value = (field.min != ''  ? field.min : '?') + ' - ' + (field.max != '' ? field.max : '?');
                    return {
                        key : field.key,
                        name : field.name,
                        value : value
                    }
                    break;
                case 'list':
                    return {
                        key : field.key,
                        name : field.name,
                        value : field.value.map(tag => tag.value)
                    }
                    break;
                case 'searchList':
                    return {
                        key : field.key,
                        name : field.name,
                        value : field.value.map(tag => tag.value)
                    }
                    break;
            }
        },
        apply : function() {
            this.grid.condition.tags = this.tags
            this.grid.condition.filter.use = true;
            this.condition.window.show = false;
            this.grid.$action.updateCondition(this.grid, 'filterApply', this.filter);
        },
        cancel : function() {
            this.grid.condition.filter.use = false;
            this.condition.window.expect = true;
            this.grid.$action.updateCondition(this.grid, 'filterCancel', {});
        },
        clear : function() {
            this.filter.fields.filter(field => field.show).forEach(field => {
                this.clearField(field.key);
                this.condition.window.expect = true;
            });
        },
        createProfile : function() {
            this.$refs.createProfile.validate((valid) => {
                if (valid) {
                    let key = (function searchKey(i){
                        return i in this.grid.filterProfile ? searchKey.call(this,++i) : i;
                    }.call(this,2))

                    this.grid.filterProfile[key] = {
                        name : this.condition.profile.creation.form.name,
                        key : key,
                        fields : Object.keys(this.filter.fields).map(keyField => {
                            let field = {}
                            Object.keys(this.filter.fields[keyField]).forEach(row => {
                                field[row] = this.filter.fields[keyField][row];
                            })
                            return field;
                        })
                    };

                    this.condition.profile.creation.form.name = '';
                    this.condition.profile.creation.use = false
                    this.condition.window.expect = true;
                    this.updateClone(key);
                    this.selectProfile(key);
                    this.grid.$action.updateCondition(this.grid, 'createProfile', key);
                }
            })
        },
        renameProfile : function() {
            this.condition.profile.rename = !this.condition.profile.rename;
            if(this.condition.profile.rename)
                this.condition.profile.creation.form.name = this.grid.filterProfile[this.grid.condition.filter.profile].name;
            this.condition.window.expect = true;
            if(!this.condition.profile.rename)
                this.$refs.updateProfile.validate((valid) => {
                    if (valid) {
                        this.grid.filterProfile[this.grid.condition.filter.profile].name = this.condition.profile.creation.form.name
                        this.condition.profile.creation.form.name = '';
                        if(this.grid.filterProfile[this.grid.condition.filter.profile].name == this.grid.filterProfileClone[this.grid.condition.filter.profile].name)
                            this.grid.condition.filter.updateProfile = false
                        else
                            this.grid.condition.filter.updateProfile = true
                    }
                })
        },
        selectProfile : function(key){
            this.grid.filterProfile[key] = JSON.parse(JSON.stringify(this.grid.filterProfileClone[key]));
            if(key != null) {
                this.grid.condition.filter.profile = key;
                this.grid.condition.filter.updateProfile = false;
            }
        },
        showDropMenu :  function($event){
            $event.stopPropagation();

            this.condition.drop.show = true;
            this.$nextTick(() => {
                let buttonRect = this.$refs.dropMenuButton.$refs._ref.getBoundingClientRect(),
                    rect = this.$refs.dropMenu.getBoundingClientRect();

                if((buttonRect.bottom + rect.height) > window.innerHeight)
                    this.$refs.dropMenu.style.top = (window.innerHeight - rect.height - 25 ) + 'px';
                else
                    this.$refs.dropMenu.style.top = (buttonRect.top - 10) + "px";

                this.$refs.dropMenu.style.left = (buttonRect.right + 10) + "px";
                this.$refs.popper.style.left = (buttonRect.right + 5) + "px";
                this.$refs.popper.style.top = (buttonRect.top + (buttonRect.height / 2) - 5) + "px";

                document.addEventListener('click',this.hideDropMenu);
            });
        },
        hideDropMenu : function($event) {
            if($event.type == 'scroll' || $event.target.closest('.vdg_contextMenuWrapper.' + this.name) == null){
                this.condition.drop.show = false;
                document.removeEventListener('click',this.hideDropMenu)
            }
        },
        buttonAddProfile : function(){
            return h('button',{
                    class : ['el-button','el-button--default'],
                    onClick : () => {
                        this.condition.profile.creation.use = true;
                        this.condition.window.expect = true;
                    }
                },
                ['Создать новый профиль',h(resolveComponent('el-icon'), () => h(resolveComponent('plus')))]
            )
        },
        formAddProfile : function(){
            return h(resolveComponent('el-form'),{
                    ref : 'createProfile',
                    model : this.condition.profile.creation.form,
                    rules : this.condition.profile.creation.rules,
                },
                () => [
                    h(resolveComponent('el-form-item'), {prop : 'name'},
                        () => h(resolveComponent('el-input'), {
                                maxlength : 30,
                                showWordLimit : true,
                                placeholder : 'Введите название профиля',
                                'modelValue' : this.condition.profile.creation.form.name,
                                'onUpdate:modelValue': value => this.condition.profile.creation.form.name = value,
                            }
                        )
                    ),
                    h('button',{
                            class : ['el-button','el-button--default'],
                            onClick : $e => {
                                $e.preventDefault();
                                this.createProfile();
                            },
                        },
                        ['Сохранить',h(resolveComponent('el-icon'),() => h(resolveComponent('check')))]
                    ),
                    h('button',{
                            class : ['el-button','el-button--default'],
                            onClick : $e => {
                                $e.preventDefault();
                                this.condition.profile.creation.use = false;
                                this.condition.window.expect = true;
                            }
                        },
                        ['Отменить',h(resolveComponent('el-icon'),() => h(resolveComponent('close')))]
                    )
                ]
            )
        },
        userProfiles : function(){
            return h(resolveComponent ('el-scrollbar'), {class : [{active : this.condition.profile.creation.use}]},
                () => h('div',{ref : 'spWrap'},
                    Object.keys(this.grid.filterProfileClone).map(key => {
                        let profile = this.grid.filterProfileClone[key];
                        return h('button',{
                                class : ['el-button','el-button--default',{selected : key == this.grid.condition.filter.profile}],
                                onClick : () => this.selectProfile(key),
                            },
                            profile.name
                        )
                    })
                )
            )
        },
        sectionProfiles : function(){
            return h(
                'div', {}, [
                    h('h3', {},'Выбор профиля'),
                    this.userProfiles(),
                    this.condition.profile.creation.use ? this.formAddProfile() : this.buttonAddProfile(),
                ]
            )
        },
        dropMenu : function(){
            return h(Teleport,{to : "body"}, h(Transition, {name : 'fade'},() =>  h('div',[
                    h('span', {class : 'main_popper',ref : 'popper'}),
                    h('div', {
                            ref : 'dropMenu',
                            class : ['vdg_contextMenuWrapper',this.name],
                        }, this.filter.fields.filter(field => !field.show).map(field => {
                            return h('div', {
                                onClick : $event => {
                                    field.show = true;
                                    this.condition.window.expect = true;
                                    if(this.filter.fields.filter(field => !field.show).length)
                                        this.showDropMenu($event)
                                    this.grid.$action.updateCondition(this.grid, 'showField', field);
                                }
                            }, field.name)
                        })
                    )
                ]
            )))
        },
        sectionFilters : function(){
            console.log(this,JSON.parse(JSON.stringify(this.grid.condition.filter)),JSON.parse(JSON.stringify(this.grid.name)),7777);
            return h('div', {
                    class : ['vdg_filter_window',{profile : this.grid.config.useFilterProfile}],
                }, [
                this.grid.config.useFilterProfile ? this.sectionProfiles() : null,
                    h('div', {}, [
                        this.grid.config.useFilterProfile ? h('h3',{}, [
                                        'Активный профиль : ',
                                        !this.condition.profile.rename ? h('span', this.filter.name) :
                                            h(resolveComponent('el-form'),{
                                                    ref : 'updateProfile',
                                                    model : this.condition.profile.creation.form,
                                                    rules : this.condition.profile.creation.rules,
                                                }, () => h(resolveComponent('el-form-item'), {prop : 'name'},
                                                    () => h(resolveComponent('el-input'), {
                                                            maxlength : 30,
                                                            showWordLimit : true,
                                                            autofocus : true,
                                                            placeholder : 'Введите название профиля',
                                                            'modelValue' : this.condition.profile.creation.form.name,
                                                            'onUpdate:modelValue': value => this.condition.profile.creation.form.name = value
                                                        }
                                                    )
                                                )
                                            ),
                                    this.grid.condition.filter.profile !== 1 ? h(resolveComponent('el-icon'),{
                                        onClick : () => this.renameProfile()
                                    },() => h(resolveComponent(this.condition.profile.rename ? 'check' : 'EditPen' ))) : ''
                                ]
                            ) : null,
                            h(resolveComponent ('el-scrollbar'), {},
                                () => h('div',{ref : 'sfWrap'},
                                    Object.keys(this.filter.fields).map(key => {
                                        let element = this.filter.fields[key];
                                        return (element.type in this && element.show) ? this[element.type](key) : '';
                                    })
                                )
                            ),
                            h('div',{},[
                                    this.condition.drop.button ? h(resolveComponent ('el-button'), {
                                            ref : 'dropMenuButton',
                                            class : ['vdg_filter_dropMenu', this.name],
                                            onClick : $event => this.showDropMenu($event)
                                        }, () => '+'
                                    ) : null,
                                    this.grid.condition.filter.profile != 1 ? h(resolveComponent ('el-button'), {
                                            class : ['el-button--danger', 'is-plain'],
                                            onClick : () => {
                                                this.grid.$action.updateCondition(this.grid, 'removeProfile', this.grid.condition.filter.profile);
                                                this.selectProfile(1)
                                                this.condition.window.expect = true;
                                            }
                                        }, () => 'Удалить профиль'
                                    ) : null,
                                    this.grid.condition.filter.profile != 1 && this.grid.condition.filter.updateProfile ? h(resolveComponent ('el-button'), {
                                            class : ['el-button--success', 'is-plain'],
                                            onClick : () => {
                                                this.grid.$action.updateCondition(this.grid, 'updateProfile', this.grid.condition.filter.profile)
                                                this.updateClone(this.grid.condition.filter.profile)
                                                this.condition.window.expect = true;
                                            }
                                        }, () => 'Сохранить профиль'
                                    ) : null,
                                    this.condition.profile.filled && this.grid.condition.filter.use ? h(resolveComponent ('el-button'), {
                                            class : ['vdg_filter_clear', 'el-button', 'el-button--default', {last : this.condition.profile.filled}],
                                            onClick : () =>  this.cancel()
                                        },
                                        () =>  'Сбросить'
                                    ) : null,
                                    this.condition.profile.filled && !this.grid.condition.filter.use && this.grid.condition.filter.profile == 1 ?
                                        h(resolveComponent ('el-button'), {
                                            class : ['vdg_filter_clear', 'el-button', 'el-button--default', {last : this.condition.profile.filled}],
                                            onClick : () =>  this.clear()
                                        },
                                        () => 'Очистить'
                                    ) : null,
                                    h(resolveComponent ('el-button'), {
                                            class : ['el-button', 'el-button--primary',
                                                {last : !this.condition.profile.filled},
                                                {last : this.grid.condition.filter.profile != 1 && !this.grid.condition.filter.use},
                                            ],
                                            onClick : () => this.apply()
                                        },() => 'Найти'
                                    )
                                ]
                            )
                        ]
                    )
                ]
            );
        }
    },
    render(){
        return h('div',{class : ['vdg_filter', this.name]}, [
                h(resolveComponent('el-input'), {
                        placeholder : 'Фильтр',
                        onClick : () => this.open()
                    },
                    {
                        prefix : () => this.grid.condition.filter.use ? this.grid.condition.tags.map(tag => {
                            return h(resolveComponent('el-tag'),{
                                closable : tag.key !== 'all',
                                size : 'large',
                                onClose : function (){
                                    this.clearField(tag.key);
                                    if(this.tags.length)
                                        this.apply();
                                    else
                                        this.cancel();
                                }.bind(this)
                            }, () => (tag.name + ' : ' + tag.value).slice(0,87) + ((tag.name + ' : ' + tag.value).length >= 90 ? '...' : ''))
                        }) : null,
                        suffix : () => this.grid.condition.filter.use ? h(resolveComponent('el-tag'),{
                            size : 'large',
                            closable : true,
                            onClose : function (){
                                this.cancel();
                            }.bind(this)
                        }) : null
                    }
                ),
                h( Transition, {name : 'el-fade-in-linear'},
                    () => this.condition.window.show ? this.sectionFilters() : null
                ),
                this.condition.drop.show ? this.dropMenu() : null
            ]
        );
    },
    mounted(){document.addEventListener('scroll', this.hideDropMenu)},
    unmounted(){document.removeEventListener('scroll', this.hideDropMenu)}
}
</script>
