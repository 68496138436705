<template>
    <template v-if="global.entity.user.checkRight(7)">
        <div class="list-wrapper-header">
            <h4>Список организаций</h4>
            <el-button class="add"  @click="$router.push('/organization/add')" v-if="global.entity.user.checkRight(15)">Добавить организацию</el-button>
            <el-button v-if="!editable && global.entity.user.checkRight(17)" class="edit" @click="edit">Редактировать</el-button>
            <el-button v-if="editable" type="success" icon="Check" class="save" @click="save">Сохранить</el-button>
            <el-button v-if="editable" type="danger" icon="Close" class="cancel" @click="cancel">Отменить</el-button>
        </div>

        <vdg :name="name" />
    </template>
    <NotRight v-else />
</template>
<script>
import headerJson from './header.json'
import filterJson from "./filter.json";
import {inject} from "vue";
import {useRoute, useRouter} from "vue-router";

export default {
    name: "OrganizationList",
    setup(){
        const global = inject('global');

        const name = 'organization';

        const content = {
            action : {
                fetch : global.entity.grid.fetch(global),
                initBeforeHeader : (grid) => {
                    let obj = {
                        8 : 'name',
                        9 : 'legal_name',
                        10 : 'address',
                        11 : 'inn',
                        12 : 'kpp',
                        13 : 'ogrn',
                        14 : 'active',
                    }
                    for(let key in obj)
                        grid.header[obj[key]].exclude = !global.entity.user.checkRight(key);
                }
            },
            header : headerJson,
            filter : global.entity.grid.filterUpdate(filterJson),
            router : useRouter(),
            route : useRoute(),
            pagination : global.entity.grid.pagination(),
            config : global.entity.grid.config(global,'/organization'),
            rowContext : {
                ...global.entity.grid.context({
                    ctx : global,
                    entity : 'organization',
                    deactivation : true,
                    activation : true,
                    edit : true,
                    remove : true,
                })
            },
            wrapperCell : {
                ...global.entity.grid.cell({
                    radio : true,
                    string : true
                }),
            }
        };

        const grid = global.entity.grid.init(name, content);

        return {name, grid, global}
    },
    data : function(){
        return {
            editable : false,
        }
    },
    methods : {
        edit : function(){
            this.editable = true;
            this.global.entity.grid.editable = true;
            this.grid.$action.extReload(this.grid);
        },
        save : function(){
            this.editable = false;
            this.global.entity.grid.editable = false;
            let elements = [];

            this.grid.elements.forEach(element => {
                elements.push({
                    id          : element.id[0].value,
                    name        : element.name[0].value,
                    legal_name  : element.legal_name[0].value,
                    address     : element.address[0].value,
                    inn         : element.inn[0].value,
                    kpp         : element.kpp[0].value,
                    ogrn        : element.ogrn[0].value,
                    active      : element.active[0].value
                })
            })

            this.grid.$action.fetch(this.grid, this.global.entity.site.domain + '/organization/updates',{
                elements : elements
            }).then(res => {
                if(res.user.authorize)
                    this.grid.$action.extReload(this.grid);
            }).catch(() => {
                this.global.notify.error();
            })
        },
        cancel : function(){
            this.editable = false;
            this.global.entity.grid.editable = false;
            this.grid.$action.extReload(this.grid);
        }
    }
}
</script>
