<template>
    <template v-if="global.entity.user.checkRight(46)">
        <div class="list-wrapper-header">
            <h4>Добавить пользователя</h4>
        </div>

        <div class="form-wrapper">
            <el-form
                ref="form"
                :model="form"
                :rules="rule"
                label-width="120px"
                status-icon
                label-position="top"
                v-loading="loading"
            >
                <el-form-item label="Логин" prop="login">
                    <el-input v-model="form.login" />
                </el-form-item>

                <el-form-item label="Фамилия Имя Отчество" prop="name">
                    <el-input v-model="form.name" />
                </el-form-item>

                <el-form-item label="Email">
                    <el-input v-model="form.email" />
                </el-form-item>

                <template v-for="(item,index) in form.phone" :key="item.key">
                    <el-form-item label="Телефон" v-if="index == 0">
                        <el-input @blur="blurPhone" placeholder="+7 (###) ###-##-##" v-maska="'+7 (###) ###-##-##'" v-model="item.value" />
                    </el-form-item>
                    <el-form-item class="field-phone-number" v-else>
                        <el-input @blur="blurPhone" placeholder="+7 (###) ###-##-##" v-maska="'+7 (###) ###-##-##'" v-model="item.value" />
                        <el-button @click="removePhone(item.key)" icon="Close"></el-button>
                    </el-form-item>
                    <el-form-item v-if="index == form.phone.length - 1">
                        <el-button @click="addPhone" icon="Check" class="button-add-number">Добавить телефон</el-button>
                    </el-form-item>
                </template>

                <el-form-item label="Должность" prop="position">
                    <el-input v-model="form.position" />
                </el-form-item>

                <el-form-item label="Город проживания" prop="city">
                    <el-input v-model="form.city" />
                </el-form-item>

                <el-form-item label="Пароль" prop="pwd">
                    <el-input type="password" autocomplete="off" v-model="form.pwd" />
                </el-form-item>

                <el-form-item :class="['is-required']" label="Повторите пароль" prop="confirm">
                    <el-input type="password" autocomplete="off" v-model="form.confirm" />
                </el-form-item>

                <el-form-item>
                    <el-button class="form-button" type="primary" plain @click="submit">Добавить</el-button>
                </el-form-item>
            </el-form>
        </div>
    </template>
    <NotRight v-else />
</template>
<script>
import {inject} from "vue";

export default {
  name: "UserAdd",
    setup(){
        const global = inject('global')
        return {global}
    },
    data : function(){
      return {
          loading : false,
          form : {
              login : '',
              name : '',
              email : '',
              phone : [
                  {
                      key : 1,
                      value : "",
                  }
              ],
              position : '',
              city : '',
              pwd : '',
              confirm : '',

          },
          rule : {
              login : [{ required: true, message: 'Поле "Логин" обязательно для заполнения', trigger: 'blur' }],
              pwd : [{ required: true, message: 'Поле "Пароль" обязательно для заполнения', trigger: 'blur'  }],
              name : [{ required: true, message: 'Поле "Фамилия Имя Отчество" обязательно для заполнения', trigger: 'blur'  }],
              position : [{ required: true, message: 'Поле "Должность" обязательно для заполнения', trigger: 'blur'  }],
              city : [{ required: true, message: 'Поле "Город проживания" обязательно для заполнения', trigger: 'blur'  }],
              confirm : [{validator: this.confirmPwd, trigger: 'blur'}]
          }
      }
    },
    methods : {
        addPhone : function(){
          this.form.phone = this.form.phone.map((el,i) => {return {key : i, value : el.value}})
          this.form.phone.push({value: '', key : this.form.phone.length})
        },
        removePhone : function(key){
            this.form.phone = this.form.phone
                .filter(el => key != el.key).map((el,i) => {return {key : i, value : el.value}})
        },
        blurPhone : function(){
            this.form.phone = this.form.phone.map(el => {
                if(el.value.length < 18)
                    el.value = ''

                return el
            })
        },
        confirmPwd : function(rule, value, callback){
                if (value !== this.form.pwd)
                    return callback(new Error("Пароли не совпадают!"))
                else
                    return callback()
        },
        submit : function(){
            this.loading = true
            this.$refs.form.validate((isValid) => {
                if(isValid){
                    let clone = JSON.parse(JSON.stringify(this.form))
                    clone.phone = clone.phone.filter(el => el.value)
                    this.global.fetch('/user/add',clone).then(res => res.json()).then(res => {
                        if(res.user.authorize) {
                            this.global.notify.message({
                                title: "Успешно!",
                                text: "Пользователь успешно добавлен",
                                type: 'success'
                            });
                            this.$router.push('/user')
                        }
                    }).catch(() => {
                        this.global.notify.error();
                    }).finally(() => this.loading = false)
                }else
                    this.loading = false
            })
        }
    }
}
</script>
