<template>
    <template v-if="global.entity.user.checkRight(15)">
        <div class="list-wrapper-header">
            <h4>Добавить организацию</h4>
        </div>
        <div class="form-wrapper">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                v-loading="loading"
                label-width="120px"
                status-icon
                label-position="top"
            >
                <el-form-item label="Наименования организации" prop="name">
                    <el-input v-model="form.name" />
                </el-form-item>
                <el-form-item label="Юридическое наименования организации" prop="legal_name">
                    <el-input v-model="form.legal_name" />
                </el-form-item>
                <el-form-item label="Адрес организации" prop="address">
                    <el-input v-model="form.address" />
                </el-form-item>
                <el-form-item label="ИНН организации" prop="inn">
                    <el-input v-model="form.inn" />
                </el-form-item>
                <el-form-item label="КПП организации" prop="kpp">
                    <el-input v-model="form.kpp" />
                </el-form-item>
                <el-form-item label="ОГРН организации" prop="ogrn">
                    <el-input v-model="form.ogrn" />
                </el-form-item>
                <el-form-item>
                    <el-button class="form-button" type="primary" plain @click="add">Доавить</el-button>
                </el-form-item>
            </el-form>
        </div>
    </template>
    <NotRight v-else />
</template>
<script>

import {inject} from "vue";

export default {
    name: "OrganizationAdd",
    setup : function(){
        let global = inject('global');
        return { global };
    },
    data : function(){
        return {
            loading : false,
            form : {
                name : '',
                legal_name : '',
                address : '',
                inn : '',
                kpp : '',
                ogrn : '',
            },
            rules : {
                name        : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                legal_name  : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                address     : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                inn         : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                kpp         : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                ogrn        : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
            }
        }
    },
    methods : {
        add : function(){
            this.$refs.form.validate((valid) => {
                if(valid) {
                    this.loading = true
                    this.global.fetch('/organization/add',{
                        ...this.form
                    }).then(res => res.json()).then(res => {
                        if(res.user.authorize){
                            this.global.notify.message({
                                title : "Успешно!",
                                text : "Организация успешно добавлена",
                                type : 'success'
                            });
                            this.$router.push('/organization')
                        }
                    }).catch(() => {
                        this.global.notify.error();
                    }).finally(() => this.loading = false)
                }
            })
        }
    }
}
</script>
