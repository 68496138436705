<script>

import { h, resolveComponent } from 'vue'

export default {
    name : 'searchList',
    props : {
        fetch: {
            type : Function,
            default : function(){}
        },
        onChange: {
            type : Function,
            default : function(){}
        },
        input: {
            type : Function,
            default : function(value){
                this.query = value
                return value
            }
        },
        tags : {
            type : Array,
            default : []
        },
        list : {
            type : Array,
            default : []
        },
        multiple : {
            type : Boolean,
            default : true
        },
        load : {
            type : Boolean,
            default : true
        }
    },
    data : function(){
      return {
          popover : false,
          query : '',
          loading : false,
          message : 'Введите минимум 3 символа',
          popperOffset : 10
      }
    },
    methods : {
      hide : function(){
          if(this.popover){
              this.popover = false
              this.query = ''
              document.removeEventListener('click', this.hide)
          }else
            this.popover = true
      }
    },
    render(){
        return h('div',{class : 'search-list-wrapper'},[
            this.tags.length ? h('div', {class : ['search-list-tag-wrapper']},  this.tags.map(tag => {
                return h(resolveComponent('el-tag'),{
                    closable : true,
                    size : 'small',
                    effect : "light",
                    type : 'info',
                    onClose : () => {
                        let key = null;
                        this.tags.forEach((el,i) => {
                            if(tag.key == el.key)
                                key = i
                        })
                        this.tags.splice(key,1)
                        let selected = this.tags.map(el => el.key);
                        Object.keys(this.list).forEach(index => {
                            this.list[index].selected = selected.includes(this.list[index].key);
                        })
                    }
                }, () => tag.value)
            })) : null,
            h(
                resolveComponent('el-popover'),{
                    offset : this.popperOffset,
                    visible : this.popover,
                    placement : 'bottom-start',
                    popperClass : this.loading ? "search-list-popper" : "search-list-popper-message",
                    width  : 538
                },{
                    default : () => h(resolveComponent('el-scrollbar'),{
                            style : {
                                height : (this.loading ? (this.list.length > 4 ? 150 : (this.list.length * 34)) : 37) + "px"
                            }
                        },
                        () => this.loading ? this.list.map(el => {
                            return h("span",{
                                class : ['search-list-element-li',{selected : el.selected}],
                                key : el.key,
                                onClick : $e => {
                                    $e.stopPropagation();
                                    this.$refs.searchInput.focus();
                                    let key = null;
                                    this.tags.forEach((tag,i) => {
                                        if(tag.key == el.key)
                                            key = i
                                    })
                                    if(null !== key)
                                        this.tags.splice(key,1)
                                    else {
                                        if(!this.multiple)
                                            this.tags.splice(0,this.tags.length)

                                        this.tags.push({key: el.key, value: el.value})
                                    }

                                    let selected = this.tags.map(el => el.key);
                                    Object.keys(this.list).forEach(index => {
                                        this.list[index].selected = selected.includes(this.list[index].key);
                                    })

                                    this.popperOffset = 0
                                    this.$nextTick(() => {
                                        this.popperOffset = 10
                                        this.onChange()
                                    })
                                }
                            },el.value)
                        }) : h('span',{class : 'message'},this.message)
                    ),
                    reference : () =>
                        h(
                        resolveComponent('el-input'),{
                            ref : 'searchInput',
                            class : ['search-list',{focused : this.popover},{'no-radius-top' : this.tags.length}],
                            'modelValue' : this.query,
                            'onUpdate:modelValue': value => {
                                value = this.input(value);
                                if(this.load) {
                                    this.loading = false;
                                    setTimeout((search) => {
                                        if (this.query == search) {
                                            if (this.query.length >= 3) {
                                                this.message = 'Загрузка...'
                                                this.fetch({
                                                    ctx: this,
                                                }).then(res => {
                                                    let selected = this.tags.map(el => el.key);
                                                    this.list.splice(0,this.list.length)
                                                    res.forEach(el => {
                                                        el.selected = selected.includes(el.key);
                                                        this.list.push(el);
                                                    })
                                                    if (!this.list.length) {
                                                        this.loading = false;
                                                        this.message = 'Ничего не найдено'
                                                    } else {
                                                        this.loading = true;
                                                    }
                                                }).catch(() => {
                                                    this.message = 'Возникла проблема!'
                                                });
                                            } else {
                                                this.message = 'Введите минимум 3 символа'
                                            }
                                        }
                                    }, 600, value)
                                }else{

                                }
                            },
                            onFocus : () => {
                                if(!this.load){
                                    this.loading = true;
                                }else if(this.load && !this.list.length){
                                    this.fetch({
                                        ctx: this,
                                    }).then(res => {
                                        let selected = this.tags.map(el => el.key);
                                        this.list.splice(0,this.list.length)
                                        res.forEach(el => {
                                            el.selected = selected.includes(el.key);
                                            this.list.push(el);
                                        })
                                        if (!this.list.length) {
                                            this.loading = false;
                                            this.message = 'Ничего не найдено'
                                        } else {
                                            this.loading = true;
                                        }
                                    }).catch(() => {
                                        this.message = 'Возникла проблема!'
                                    });
                                }
                            },
                            onClick : $e => {
                                if(!this.popover){
                                    document.addEventListener('click', this.hide)
                                }else
                                    $e.stopPropagation();
                            },
                            onBlur : () => {
                                if(!this.list.length) {
                                    this.message = 'Введите минимум 3 символа'
                                    this.loading = false;
                                }
                            }
                        }
                    )
                }
            )
        ])
    },
}
</script>
