<template>
    <template v-if="global.entity.user.checkRight(47)">
        <template v-if="notElement">
            <div class="list-wrapper-header">
                <h4>Редактировать пользователя : {{form.name}}</h4>
            </div>

            <div class="form-wrapper">
                <el-form
                    ref="form"
                    :model="form"
                    :rules="rule"
                    label-width="120px"
                    status-icon
                    label-position="top"
                    v-loading="loading"
                >

                    <el-form-item label="Фамилия Имя Отчество" prop="name">
                        <el-input v-model="form.name" :disabled="!global.entity.user.checkRight(48)" />
                    </el-form-item>

                    <el-form-item label="Email">
                        <el-input v-model="form.email"  :disabled="!global.entity.user.checkRight(50)" />
                    </el-form-item>

                    <el-form-item label="Телефон" style="margin-bottom: 0"></el-form-item>
                    <template v-for="(item,index) in form.phone_back" :key="'back_' + item.id">
                        <el-form-item class="field-phone-number">
                            <el-input @blur="blurPhone" placeholder="+7 (###) ###-##-##" v-maska="'+7 (###) ###-##-##'" v-model="item.number" :disabled="!global.entity.user.checkRight(49)" />
                            <el-button @click="unsetPhone(index, item.id)" icon="Close"  :disabled="!global.entity.user.checkRight(49)" ></el-button>
                        </el-form-item>
                    </template>
                    <template v-if="global.entity.user.checkRight(49)" v-for="(item,index) in form.phone" :key="item.key">
                        <el-form-item class="field-phone-number">
                            <el-input @blur="blurPhone" placeholder="+7 (###) ###-##-##" v-maska="'+7 (###) ###-##-##'" v-model="item.value" />
                            <el-button @click="removePhone(item.key)" icon="Close"></el-button>
                        </el-form-item>
                    </template>
                    <el-form-item v-if="global.entity.user.checkRight(49)">
                        <el-button @click="addPhone" icon="Check" class="button-add-number">Добавить телефон</el-button>
                    </el-form-item>

                    <el-form-item label="Должность" prop="position">
                        <el-input v-model="form.position" :disabled="!global.entity.user.checkRight(51)"/>
                    </el-form-item>

                    <el-form-item label="Город проживания" prop="city">
                        <el-input v-model="form.city" :disabled="!global.entity.user.checkRight(52)"/>
                    </el-form-item>

                    <template v-if="global.entity.user.checkRight(54)">
                        <el-form-item label="Пароль" prop="pwd">
                            <el-input type="password" autocomplete="off" v-model="form.pwd" />
                        </el-form-item>

                        <el-form-item :class="['is-required']" label="Повторите пароль" prop="confirm">
                            <el-input type="password" autocomplete="off" v-model="form.confirm" />
                        </el-form-item>
                    </template>

                    <el-form-item label="Активность">
                        <el-switch v-model="form.active" :disabled="!global.entity.user.checkRight(53)"/>
                    </el-form-item>

                    <el-form-item>
                        <el-button class="form-button" type="primary" plain @click="submit">Сохранить</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </template>
        <template v-else>
            Элемент не найден
        </template>
    </template>
    <NotRight v-else />
</template>
<script>
import {inject} from "vue";

export default {
    name: "UserEdit",
    setup(){
        let global = inject('global')

        return {global}
    },
    data : function(){
        return {
            loading : false,
            notElement : false,
            form : {
                id : null,
                login : '',
                name : '',
                email : '',
                phone : [],
                phone_back : [],
                phone_remove : {},
                position : '',
                city : '',
                pwd : '',
                confirm : '',
                active : false,
            },
            rule : {
                name : [{ required: true, message: 'Поле "Фамилия Имя Отчество" обязательно для заполнения', trigger: 'blur'  }],
                position : [{ required: true, message: 'Поле "Должность" обязательно для заполнения', trigger: 'blur'  }],
                city : [{ required: true, message: 'Поле "Город проживания" обязательно для заполнения', trigger: 'blur'  }],
                confirm : [{validator: this.confirmPwd, trigger: 'blur'}]
            }
        }
    },
    methods : {
        addPhone : function(){
            this.form.phone = this.form.phone.map((el,i) => {return {key : i, value : el.value}})
            this.form.phone.push({value: '', key : this.form.phone.length})
        },
        removePhone : function(key){
            this.form.phone = this.form.phone
                .filter(el => key != el.key).map((el,i) => {return {key : i, value : el.value}})
        },
        unsetPhone : function(index, id){
            this.form.phone_back.splice(index,1);
            this.form.phone_remove[id] = id
        },
        blurPhone : function(){
            this.form.phone = this.form.phone.map(el => {
                if(el.value.length < 18)
                    el.value = ''

                return el
            })
        },
        confirmPwd : function(rule, value, callback){
            if (value !== this.form.pwd)
                return callback(new Error("Пароли не совпадают!"))
            else
                return callback()
        },
        submit : function(){
            this.$refs.form.validate((isValid) => {
                if(isValid){
                    let clone = JSON.parse(JSON.stringify(this.form))
                    clone.phone = clone.phone.filter(el => el.value)
                    this.global.fetch('/user/'+ this.$route.params.element + '/update',this.form).then(res => res.json()).then(res => {
                        if(res.user.authorize) {
                            this.global.notify.message({
                                title: "Успешно!",
                                text: "Пользователь успешно измененн",
                                type: 'success'
                            });
                            this.$router.push('/user')
                        }
                    }).catch(() => {
                        this.global.notify.error();
                    })
                }
            })
        }
    },
    created() {
        console.log('created');
        this.global.fetch('/user/'+ this.$route.params.element,{}).then(res => res.json()).then(res => {
            console.log(res);
            if(res.user.authorize && res.element){
                this.notElement = true;
                this.form.name          = res.element.name;
                this.form.email         = res.element.email;
                this.form.position      = res.element.position;
                this.form.city          = res.element.city;
                this.form.id            = res.element.id;
                this.form.active        = !!res.element.active;
                this.form.phone_back    = res.element.phones;
            }
        }).catch(() => {
            this.global.notify.error();
        })
    }
}
</script>
