<template>
    <template v-if="global.entity.user.checkRight(26)">
        <div class="list-wrapper-header">
            <h4>Список групп пользователей</h4>
            <el-button class="add" @click="$router.push('/group/add')" v-if="global.entity.user.checkRight(30)">Добавить группу</el-button>
            <el-button v-if="!editable && global.entity.user.checkRight(31)" class="edit" @click="edit">Редактировать</el-button>
            <el-button v-if="editable" type="success" icon="Check" class="save" @click="save">Сохранить</el-button>
            <el-button v-if="editable" type="danger" icon="Close" class="cancel" @click="cancel">Отменить</el-button>
        </div>

        <vdg :name="name" />
    </template>
    <NotRight v-else />
</template>

<script>
import { inject } from "vue";
import headerJson from './header.json'
import filterJson from "./filter.json";
import { useRoute, useRouter } from "vue-router";

export default {
    name: "GroupList",
    setup(){
        const global = inject('global')
        const router = useRouter();
        const route = useRoute();
        const name = 'group';
        const content = {
            action : {
                fetch : global.entity.grid.fetch(global),
                initBeforeHeader : (grid) => {
                    let obj = {
                        27 : 'name',
                        28 : 'right',
                        29 : 'active',
                    }
                    for(let key in obj)
                        grid.header[obj[key]].exclude = !global.entity.user.checkRight(key);
                }
            },
            header  : headerJson,
            filter  : global.entity.grid.filterUpdate(filterJson),
            router  : router,
            route   : route,
            pagination : global.entity.grid.pagination(),
            config : global.entity.grid.config(global,'/group'),
            rowContext : {
                ...global.entity.grid.context({
                    ctx : global,
                    entity : 'group',
                    deactivation : true,
                    activation : true,
                    remove : true,
                }),
                right : {
                    text      : 'Настроить права',
                    function  : function({row}){
                        router.push('/group/'+row.id[0].value + '/right')
                    }
                },
            },
            wrapperCell : {
                ...global.entity.grid.cell({
                    radio : true,
                    string : true
                }),
            }
        };

        const grid = global.entity.grid.init(name, content);

        return {name, grid, global}
    },
    data : function(){
        return {
            editable : false,
        }
    },
    methods : {
        edit : function(){
            this.editable = true;
            this.global.entity.grid.editable = true;
            this.grid.$action.extReload(this.grid);
        },
        save : function(){
            this.editable = false;
            this.global.entity.grid.editable = false;
            let elements = [];

            this.grid.elements.forEach(element => {
                elements.push({
                    id : element.id[0].value,
                    name : element.name[0].value,
                    active : element.active[0].value
                })
            })

            this.grid.$action.fetch(this.grid, this.global.entity.site.domain + '/group/updates',{
                elements : elements
            }).then(res => {
                if(res.user.authorize)
                    this.grid.$action.extReload(this.grid);
            }).catch(() => {
                this.global.notify.error();
            })
        },
        cancel : function(){
            this.editable = false;
            this.global.entity.grid.editable = false;
            this.grid.$action.extReload(this.grid);
        }
    }
}
</script>
