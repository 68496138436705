<template>
    <template v-if="global.entity.user.checkRight(34)">
        <template v-if="notElement">
            <div class="list-wrapper-header">
                <h4>Права доступа : {{elementName}}</h4>
            </div>

            <vdg :name="name" />
        </template>
        <template v-else>
            Элемент не найден
        </template>
    </template>
    <NotRight v-else />
</template>

<script>
import {h, inject, resolveComponent} from "vue";
import headerJson from './header.json'
import {useRoute} from "vue-router";

export default {
    name: "GroupRightList",
    setup(){
        const global = inject('global')
        const route = useRoute();
        const name = 'group_right';
        const content = {
            action : {
                fetch : global.entity.grid.fetch(global),
            },
            header          : headerJson,
            config : {
                headerDisplaySetting : true,
                columnDisplayContext : true,
                useFilter            : false,
                useFilterProfile     : false,
                remoteElement        : true,
                remoteUrlElement     : global.entity.site.domain + '/group/'+route.params.element+'/right',
                remoteSetting        : true,
                remoteUrlSetting     : global.entity.site.domain,
            },
            wrapperCell : {
                use: function({gridRow,row,column,cell}){
                    let element = this.elements[row][column][cell];
                    return h('div',{style: {'grid-row' : gridRow}},
                        h(resolveComponent('el-switch'),{
                            'model-value' : element.value,
                            'onUpdate:modelValue': e => {
                                this.$action.fetch(this, global.entity.site.domain + '/group/'+route.params.element+'/right/save',{
                                    rights_id : this.elements[row].id[0].value,
                                    value : e,
                                }).then(res => {
                                    if(res.user.authorize)
                                        element.value = e;
                                }).catch(() => {
                                    this.global.notify.error();
                                })
                            }
                        })
                    )
                }
            }
        };
        global.entity.grid.init(name, content);
        return {name, global}
    },
    data : function(){
        return {
            loading : false,
            notElement : false,
            elementName : null
        }
    },
    created() {
        this.global.fetch('/group/'+ this.$route.params.element,{}).then(res => res.json()).then(res => {
            if(res.user.authorize && res.element){
                this.notElement = true;
                this.elementName = res.element.name;
            }
        }).catch(() => {
            this.global.notify.error();
        })
    }
}
</script>
