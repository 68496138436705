<template>
    <template v-if="global.entity.user.checkRight(16)">
        <template v-if="notElement">
            <div class="list-wrapper-header">
                <h4>Редактировать организацию : {{form.name}}</h4>
            </div>
            <div class="form-wrapper">
                <el-form
                    ref="form"
                    :model="form"
                    :rules="rules"
                    label-width="120px"
                    status-icon
                    label-position="top"
                    v-loading="loading"
                >
                    <el-form-item label="Наименования организации" prop="name">
                        <el-input v-model="form.name" :disabled="!global.entity.user.checkRight(8)" />
                    </el-form-item>
                    <el-form-item label="Юридическое наименования организации" prop="legal_name">
                        <el-input v-model="form.legal_name" :disabled="!global.entity.user.checkRight(9)" />
                    </el-form-item>
                    <el-form-item label="Адрес организации" prop="address">
                        <el-input v-model="form.address" :disabled="!global.entity.user.checkRight(10)" />
                    </el-form-item>
                    <el-form-item label="ИНН организации" prop="inn">
                        <el-input v-model="form.inn" :disabled="!global.entity.user.checkRight(11)" />
                    </el-form-item>
                    <el-form-item label="КПП организации" prop="kpp">
                        <el-input v-model="form.kpp" :disabled="!global.entity.user.checkRight(12)" />
                    </el-form-item>
                    <el-form-item label="ОГРН организации" prop="ogrn">
                        <el-input v-model="form.ogrn" :disabled="!global.entity.user.checkRight(13)" />
                    </el-form-item>
                    <el-form-item label="Активность">
                        <el-switch v-model="form.active" :disabled="!global.entity.user.checkRight(14)" />
                    </el-form-item>
                    <el-form-item>
                        <el-button class="form-button" type="primary" plain @click="edit">Сохранить</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </template>
        <template v-else>
            Элемент не найден
        </template>
    </template>
    <NotRight v-else />
</template>
<script>

import { inject } from "vue";

export default {
    name: "OrganizationEdit",
    setup : function(){
        let global = inject('global');
        return { global };
    },
    data : function(){
        return {
            loading : false,
            notElement : false,
            form : {
                name : '',
                legal_name : '',
                address : '',
                inn : '',
                kpp : '',
                ogrn : '',
                active : true,
            },
            rules : {
                name        : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                legal_name  : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                address     : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                inn         : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                kpp         : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
                ogrn        : [{required: true, message: 'Поле обязательно для заполнения', trigger: 'blur'}],
            }
        }
    },
    methods : {
        edit : function(){
            this.$refs.form.validate((valid) => {
                if(valid) {
                    this.loading = true
                    this.global.fetch('/organization/'+ this.$route.params.element +'/update',{
                        ...this.form
                    }).then(res => res.json()).then(res => {
                        if(res.user.authorize){
                            this.$router.push('/organization')
                        }
                    }).catch(() => {
                        this.global.notify.error();
                    }).finally(() => this.loading = false)
                }
            })
        }
    },
    created() {
        this.global.fetch('/organization/'+ this.$route.params.element,{}).then(res => res.json()).then(res => {
            if(res.user.authorize && res.element){
                this.notElement = true;
                this.form.name          = res.element.name;
                this.form.legal_name    = res.element.legal_name;
                this.form.address       = res.element.address;
                this.form.inn           = res.element.inn;
                this.form.kpp           = res.element.kpp;
                this.form.ogrn          = res.element.ogrn;
                this.form.id            = res.element.id;
                this.form.active        = !!res.element.active;
            }
        }).catch(() => {
            this.global.notify.error();
        })
    }
}
</script>
