import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
//animation
import 'animate.css/animate.min.css'
//layouts hidden
import 'element-plus/theme-chalk/display.css'

import locale from 'element-plus/es/locale/lang/ru'
import dayjs from "dayjs";
import "dayjs/locale/ru";
dayjs.locale("ru");

import Maska from 'maska'

import '@/vdg/css/style.css'
import '@/vdg/css/color.css'
import '@/css/main.css'

import Grid from './vdg/components/grid.n';
import NotRight from './components/notright';

import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import './registerServiceWorker'

const app = createApp(App)
app.use(ElementPlus,  {locale})
app.use(router)
app.use(Maska)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.component('vdg', Grid)
app.component('NotRight', NotRight)

app.mount('#app')
