<template>
    <template v-if="global.entity.site.loaded">
        <div class="header">
            <a href="/" class="logo">
                <img :src="'./img/logo.png'">
                <span class="logo_main">Такси городское</span>
                <span class="logo_sub">Лицензионный отдел</span>
            </a>
            <div class="menu hidden-xs-only">
                <el-menu
                    :ellipsis="false"
                    mode="horizontal"
                    default-active="2"
                    @open="handleOpen"
                    @close="handleClose"
                >
                    <el-menu-item index="location">
                        <el-icon><location /></el-icon>
                        <span>Пункты осмотра</span>
                    </el-menu-item>
                    <el-menu-item index="avatar">
                        <el-icon><avatar /></el-icon>
                        <span>Личный кабинет</span>
                    </el-menu-item>
                    <el-menu-item index="tickets">
                        <el-icon><tickets /></el-icon>
                        <span>Публичная информация</span>
                    </el-menu-item>
                </el-menu>
            </div>
            <el-icon @click="show = !show" class="mobile_expand_menu hidden-sm-and-up"><Expand /></el-icon>
        </div>

            <el-drawer
                v-model="show"
                direction="ltr"
                :with-header="false"
                size="85%"
                class="menu_drawer"
            >
                <el-icon class="close_menu" @click="show = !show"><close /></el-icon>
                <el-menu
                    default-active="2"
                    @open="handleOpen"
                    @close="handleClose"
                >
                    <el-menu-item index="location">
                        <el-icon><location /></el-icon>
                        <span>Пункты осмотра</span>
                    </el-menu-item>
                    <el-menu-item index="avatar">
                        <el-icon><avatar /></el-icon>
                        <span>Личный кабинет</span>
                    </el-menu-item>
                    <el-menu-item index="tickets">
                        <el-icon><tickets /></el-icon>
                        <span>Публичная информация</span>
                    </el-menu-item>
                </el-menu>
            </el-drawer>


        <div class="work_area main">
            <PWAPrompt />
            <template v-if="global.entity.user.authorized">
                <router-view />
            </template>
            <template v-else>
                <div class="form_auth">
                    <div class="tab_for_auth">
                        <div @click="tab = false" :class="['login',{active : !tab}]">Войти</div>
                        <div @click="tab = true" :class="['register',{active : tab}]">Зарегистрироватся</div>
                    </div>
                    <div class="form_register" v-if="tab">
                        <el-form :rules="registerRules" label-position="top" :model="registerForm">
                            <el-form-item label="Вид деятельности" prop="form">
                                <el-radio-group v-model="registerForm.form">
                                    <el-radio label="Самозанятый" />
                                    <el-radio label="Индивидуальный предпрениматель" />
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item label="ИНН" prop="inn">
                                <el-input v-model="registerForm.inn" />
                            </el-form-item>
                            <el-form-item label="Фамилия Имя Отчество" prop="full_name">
                                <el-input v-model="registerForm.full_name" />
                            </el-form-item>
                            <el-form-item label="Номер телефона" prop="phone">
                                <el-input v-model="registerForm.phone" />
                            </el-form-item>
                            <el-form-item label="Номер разрешения" prop="license">
                                <el-input v-model="registerForm.license" />
                            </el-form-item>
                            <el-form-item label="Марка авто" prop="auto_mark">
                                <el-input v-model="registerForm.auto_mark" />
                            </el-form-item>
                            <el-form-item label="Модель авто" prop="auto_model">
                                <el-input v-model="registerForm.auto_model" />
                            </el-form-item>
                            <el-form-item label="Гос. номер" prop="auto_number">
                                <el-input v-model="registerForm.auto_number" />
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="form_login" v-else>
                        <el-form
                            :model="authorize.data"
                            :rules="authorize.rules"
                            ref="auth"
                            label-width="120px"
                            label-position="top"
                            v-loading="authorize.loading"
                        >
                            <el-form-item label="Логин" prop="login">
                                <el-input v-model="authorize.data.login" />
                            </el-form-item>
                            <el-form-item label="Пароль" prop="password">
                                <el-input v-model="authorize.data.password" type="password" autocomplete="off" />
                            </el-form-item>
                            <el-button @click="login" type="primary" class="auth-button">Авторизоваться</el-button>
                        </el-form>
                    </div>
                </div>
            </template>
        </div>


        <el-dialog
            v-model="global.bridgeComponentData.NavBarToAuthorize.showWindow"
            title="Авторизация"
            class="auth-form el-col el-col-xs-22 el-col-sm-12 el-col-md-8 el-col-lg-6 el-col-xl-4"
        >
            <el-alert v-if="authorize.message != ''" :title="authorize.message" type="error" center show-icon />
            <el-form
                :model="authorize.data"
                :rules="authorize.rules"
                ref="auth"
                label-width="120px"
                label-position="top"
                v-loading="authorize.loading"
            >
                <el-form-item label="Логин" prop="login">
                    <el-input v-model="authorize.data.login" />
                </el-form-item>
                <el-form-item label="Пароль" prop="password">
                    <el-input v-model="authorize.data.password" type="password" autocomplete="off" />
                </el-form-item>
                <el-button @click="login" type="primary" class="auth-button">Авторизоваться</el-button>
            </el-form>
        </el-dialog>
    </template>
    <template v-else>
        <div class="loader" v-loading="true"></div>
    </template>

</template>

<style>
.el-overlay-dialog{
    display: flex;
}
.el-dialog.auth-form{
    margin: auto;
    display: flex;
    flex-direction: column;
}
</style>

<script>
import  NavBar  from './components/navbar'
import {h, provide, reactive, resolveComponent} from "vue";
import { ElNotification, ElLoading } from 'element-plus'
import init from './vdg/use/init';
import {useRouter} from "vue-router";
import filterJson from "@/pages/city/list/filter.json";
import {Avatar, Close} from "@element-plus/icons-vue";
import PWAPrompt from "./components/PWAPrompt";

export default {
    name : 'App',
    components : {Close, Avatar, NavBar, PWAPrompt},
    data : function(){
      return {
          tab : true,
          show : false,
          registerForm : {
              full_name : '',
              inn : '',
              form : 'Самозанятый',
              phone : '',
              license : '',
              auto_mark : '',
              auto_model : '',
              auto_number : '',
          },
          registerRules : {
              full_name: [
                  { required: true, message: 'Please input Activity name', trigger: 'blur' },
              ],
              inn: [
                  { required: true, message: 'Please input Activity name', trigger: 'blur' },
              ],
              phone: [
                  { required: true, message: 'Please input Activity name', trigger: 'blur' },
              ],
              license: [
                  { required: true, message: 'Please input Activity name', trigger: 'blur' },
              ],
          },
          authorize : {
              loading : false,
              message : '',
              data : {
                  login : '',
                  password : ''
              },
              rules: {
                  login: [{required: true, message: 'Поле "Логин" обязательно для заполнения ', trigger: 'blur'}],
                  password: [{required: true, message: 'Поле "Пароль" обязательно для заполнения ', trigger: 'blur'}],
              }
          }
      };
    },
    watch : {
        'registerForm.form' : function(val){
            console.log(val);
        }
    },
    setup(){

        const router = useRouter();

        const global = reactive({
            bridgeComponentData : {
                NavBarToAuthorize : {
                    showWindow : false
                }
            },
            entity : {
                site : {
                    domain      : 'http://'+location.host+':8000/api',//'http://94.125.52.66:8000/api',
                    loaded      : true
                },
                user : {
                    get id(){
                        return localStorage.getItem('uid')
                    },
                    set id(value){
                        return localStorage.setItem('uid', value)
                    },
                    get token(){
                        return localStorage.getItem('token')
                    },
                    set token(value){
                        return localStorage.setItem('token', value)
                    },
                    name            : '',
                    position        : '',
                    avatar          : '',
                    right           : {},
                    rightForCity    : [],
                    accessCity      : [],
                    authorized      : false,
                    create : function(obj){
                        this.authorized  = obj.authorize;
                        this.id             = obj.id;
                        this.token          = obj.token;
                        this.name           = obj.name;
                        this.position       = obj.position;
                        this.avatar         = obj.avatar;
                        this.right          = obj.right;
                        this.accessCity     = obj.accessCity;
                        this.rightForCity   = obj.rightForCity;
                    },
                    checkRight : function(right){
                        return right in this.right
                    },
                },
                grid : {
                    editable : false,
                    init     : init,
                    filterUpdate : function(filterJson){
                        for(let key in filterJson){
                            if('url' in filterJson[key])
                                filterJson[key].url = global.entity.site.domain + filterJson[key].url
                        }
                        return filterJson;
                    },
                    switchActive : (ctx,entity) => {
                        return function({row, grid}){
                            grid.$action.fetch(grid, ctx.entity.site.domain + '/' + entity + '/' + row.id[0].value + '/switch',{
                                active  : row.active[0].value == 'Да' ? false : true,
                            }).then(res => {
                                if(res.user.authorize)
                                    grid.$action.extReload(grid);
                            }).catch(() => {
                                ctx.notify.error();
                            })
                        }
                    },
                    fetch: function(context){
                        return (grid, url, body) => {
                            return new Promise((next, err) => {
                                fetch(url, {
                                    method: 'post',
                                    body: JSON.stringify({
                                        ...body,
                                        edit    : context.entity.grid.editable,
                                        user_id : context.entity.user.id,
                                        token   : context.entity.user.token,
                                    }),
                                    headers: {
                                        'Content-Type': 'application/json;charset=utf-8',
                                        ...grid.remoteQuery.header
                                    }
                                }).then(res => res.json()).then(res =>{
                                    return res;
                                }).then(res => next(res)).catch(res => err(res))
                            })
                        }
                    },
                    config :function(ctx,url){
                        return {
                            headerDisplaySetting : true,
                            columnDisplayContext : true,
                            useFilter            : true,
                            useFilterProfile     : true,
                            remoteElement        : true,
                            remoteUrlElement     : ctx.entity.site.domain + url,
                            remoteSetting        : true,
                            remoteUrlSetting     : ctx.entity.site.domain,
                        }
                    },
                    pagination :function(){
                        return {
                            default : {
                                pageSize : 20
                            },
                            top : {
                                pagerCount  : 5,
                                layout      : ['jumper', 'prev', 'pager', 'next', 'sizes', 'total'],
                                pageSizes   : [10,20,50],
                            },
                            bottom : {
                                pagerCount  : 5,
                                layout      : [ 'prev', 'pager', 'next', 'total'],
                                pageSizes   : [10,20,50],
                            }
                        }
                    },
                    context : function({ctx, activation, deactivation, edit, remove, entity}){
                        let obj  = {}
                        if(deactivation)
                            obj.deactivation = {
                                text      : 'Деактивировать',
                                function  : ctx.entity.grid.switchActive(ctx,entity)
                            }

                        if(activation)
                            obj.activation = {
                                text      : 'Активировать',
                                function  : ctx.entity.grid.switchActive(ctx,entity)
                            }

                        if(remove)
                            obj.remove = {
                                text      : 'Удалить',
                                function  : function({row, grid}){
                                    grid.$action.fetch(grid, ctx.entity.site.domain + '/' + entity + '/' + row.id[0].value + '/delete',{}).then(res => {
                                        if(res.user.authorize)
                                            grid.$action.extReload(grid);
                                    }).catch(() => {
                                        ctx.notify.error();
                                    })
                                }
                            }

                        if(edit)
                            obj.edit = {
                                text      : 'Редактировать',
                                    function  : function({row}){
                                    router.push('/' + entity + '/edit/'+row.id[0].value)
                                }
                            }

                        return obj;
                    },
                    cell : function({string, radio}){
                        let obj = {};
                        if(radio)
                            obj.radio = function({gridRow,row,column,cell}){
                                let element = this.elements[row][column][cell];
                                return h('div',{style: {'grid-row' : gridRow}},
                                    h(resolveComponent('el-switch'),{
                                        'model-value' : element.value,
                                        'onUpdate:modelValue': e => {
                                            element.value = e;
                                        }
                                    })
                                )
                            }
                        if(string)
                            obj.string = function({gridRow,row,column,cell}){
                                let element = this.elements[row][column][cell];
                                return h('div',{style: {'grid-row' : gridRow}},
                                    h(resolveComponent('el-input'),{
                                        'model-value' : element.value,
                                        'onUpdate:modelValue': e => {
                                            element.value = e;
                                        }
                                    })
                                )
                            }

                        return obj;
                    }
                },
            },
            notify : {
                message: (message) => {
                    ElNotification({
                        title: message.title,
                        message: message.text,
                        type: message.type
                    })
                },
                error : function(){
                    ElNotification({
                        title: "Возникла проблема!",
                        message: "Обратитесь к администратору",
                        type: "error"
                    })
                },
            },
            fetch : function (url, body = {}, headers = {}) {
                return fetch(this.entity.site.domain + url, {
                    method: 'post',
                    body: JSON.stringify({
                        user_id : this.entity.user.id,
                        token   : this.entity.user.token,
                        ...body
                    }),
                    headers: {
                        'Content-Type': 'application/json;charset=utf-8',
                        ...headers
                    }
                })
            },
        })

        /*router.beforeEach((to, from, next) => {
            if(!global.entity.site.loaded){
                global.fetch('/check').then(result => result.json()).then(result => {
                    if (result.user.authorize) {
                        global.entity.user.create(result.user);
                    }else{
                        global.entity.user.id     = '';
                        global.entity.user.token  = '';
                    }
                    return result.user.authorize;
                }).catch(() => {
                    global.notify.error();
                }).finally(() => {
                    global.entity.site.loaded = true
                    next();
                })
            }else{
                next();
            }
        })*/

        provide('global', global)

        return {global}
    },
    methods : {
        login : function (){
            this.$refs.auth.validate(valid => {
                if (valid) {
                    this.authorize.loading = true
                    fetch(this.global.entity.site.domain + '/login',{
                        method: 'post',
                        body: JSON.stringify({
                            login : this.authorize.data.login,
                            password : this.authorize.data.password,
                        }),
                        headers: {'Content-Type': 'application/json;charset=utf-8'}
                    }).then(res => res.json()).then(res => {
                        if(res.user.authorize) {
                            this.authorize.loading = false;
                            this.authorize.message = '';
                            this.authorize.data = {
                                    login : '',
                                    password : ''
                            }
                            this.global.bridgeComponentData.NavBarToAuthorize.showWindow = false
                            this.global.entity.user.create(res.user);
                        }else{
                            this.authorize.message = 'Неверные Логин или Пароль'
                        }
                    }).catch(() => {
                        this.authorize.message = 'Проблема с сервером'
                    }).finally(() => {
                        this.authorize.loading = false
                    })
                }
            })
        }
    }
}
</script>
