import {h} from "vue";

export default {
    columnHeader : {
        sort        : 500,      // Последовательность колонок
        show        : true,     // Отображать колонки
        child       : [],       // Вложенные колонки
        parent      : null,     // Родительская колонка
        sortable    : false,    // Будет ли колонка сортироваться
        direction   : "asc",    // Направление сортировки
        width       : 200,      // Ширина колонки
        exclude     : false     // Отключить колонку
    },
    columnHeaderIcon : {
        name : 'icon',
        width : 40,
        sort : -1,
        show : true,
        child : [],
    },
    filterType : {
        number : {
            show        : true,                 //Видимость поля в фильтре
            type        : 'number',             // Тип фильтруемого значения
            name        : 'Фильтр по числу',    // Наименование в фильтре
            min         : '',                   // Искомое значение
            max         : '',                   // Искомое значение придиапозоне
            operation   : '=',                  // Оператор сравнения
            operations   : [
                {
                    label : 'Равно',
                    value : '='
                },
                {
                    label : 'Больше чем',
                    value : '>'
                },
                {
                    label : 'Меньше чем',
                    value : '<'
                },
                {
                    label : 'Диапазон',
                    value : '><'
                },
            ],  // Список операторов сравнения >,<,=,><
            meta : {}
        },
        date : {
            show        : true,
            type        : 'date',
            name        : 'Фильтр по дате',
            min         : '',
            max         : '',
            operation   : '=',
            operations   : [
                {
                    label : 'Равно',
                    value : '='
                },
                {
                    label : 'Больше чем',
                    value : '>'
                },
                {
                    label : 'Меньше чем',
                    value : '<'
                },
                {
                    label : 'Диапазон',
                    value : '><'
                },
            ],
            meta : {}
        },
        list : {
            show        : true,
            type        : 'list',
            name        : 'Фильтр по фиксированному списку',
            option      : [],           // Варианты значений {value : 'personal', label : 'Персональная'}
            url         : '',            // Откуда загружать варианты значений,
            value       : [],           // Выбранные элементы
            meta        : {},
            multiple    : false,        // Возсожночсть множественного выбора
            load        : false
        },
        searchList : {
            show        : true,
            type        : 'searchList',
            name        : 'Фильтр по тексту со списком',
            url         : '',           // Всегда выполняет запрос на сервер
            value       : [],
            meta        : {},
            multiple    : false
        },
    },
    _system : {
        headerKeys          : null, // #array<string> Все ключи шапки
        readyHeaderKeys     : null, // #array<string> Ключи шапки участвующие в отрисовке
        gridTemplateColumns : null, // #string<'100px 200px 300px'> Ширина ряда
        sortable            : null, // #object Сортировка в сетке {direction : 'asc', field : 'headerKey'}
        scroll              : null, // #number Обшая ширина для скрола 550
        loading             : null, // #object<ElLoading>
    },
    config : {
        headerDisplaySetting : false, // Показывать иконку настройки отображения колонок
        columnDisplayContext : false, // Показывать Колонку для контекстного меню
        useFilter            : false, // Использлвать фильтр
        useFilterProfile     : false, // Использлвать профили фильтра
        remoteElement        : false, // Получать элементы с сервера
        remoteSetting        : false, // Получать и хранить профили фильтра с сервера
        remoteUrlSetting     : null,  // Адрес хранения настроек и профилей
        remoteUrlElement     : null,  // Адрес получения элементов
    },
    context                  : [], // #array[object{text,function({grid,row,event})}]Контекстное меню
    rowContext               : {}, // #object[object{text,function({grid,row,event})}]Контекстное меню для конкретного ряда
    wrapperCell : {
        default : function({gridRow, row, column, cell}){
            return h('div',{
                style : {'grid-row' : gridRow}
            },h('span', {}, this.elements[row][column][cell].value))
        }
    }, // Объект функций рендеринга для ячейки, context = gridObject
    pagination : {
        default : {
            pageSize : 20
        },
        top : {
            use         : false,
            pagerCount  : 5, // 5 - 21
            layout      : [], // sizes / prev / pager / next / jumper / -> / total / slot
            pageSizes   : [], // [10,20,50]
        },
        bottom : {
            use         : false,
            pagerCount  : 5,    // 5 - 21
            layout      : [],   // sizes / prev / pager / next / jumper / -> / total / slot
            pageSizes   : [],   // [10,20,50]
        }

    },                  // Объект постраничной навигации
    filter : {},        // Объект полей фильтрации
    router : null,      // Объект роута для постранички
    route  : null,      // Объект роута для постранички
    filterProfile : {
        1 : {
            name    : 'По умолчанию',
            fields  : {}
        }
    }, // Объект профилей фильтра
    filterProfileClone : {}, // Объект клон профилей фильтра
    condition : {
        pagination : {
            total : 1,
            page : 1,
            pageSize : 20,
        },
        filter : {
            use : false,
            updateProfile : false,
            profile : 1,
        },
        store           : 'local', // remote/local ключ объекта функций хранения настроек
        storeElement    : 'local', // remote/local ключ объекта функций хранения элементов
        tags            : [] //Массив тэгов в строке фильтра
    }, //Объект состояния сетки
    remoteQuery : {
        header  : {},
        body    : {}
    } //Дополнительные данные и заголовки для запросов на сервер
}
