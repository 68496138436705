<script>
import {h, inject, resolveComponent} from 'vue'

export default {
    name : 'Column',
    props : ['name'],
    setup(props){
        let grid = inject(props.name);
        return {grid}
    },
    mounted(){document.addEventListener('scroll', this.hideContext)},
    unmounted(){document.removeEventListener('scroll', this.hideContext)},
    methods : {
        hideContext : function(){
            if( this.grid._system.context) {
                this.grid._system.context = null;
                document.removeEventListener('click', this.hideContext)
            }
        },
        showContext : function($event,element){

            this.grid._system.context = element;

            this.$nextTick(() => {
                let icon = $event.target.closest('i'),
                iconRect = icon.getBoundingClientRect(),
                rect = this.$parent.$refs.context.getBoundingClientRect();

                if(($event.clientY + rect.height) > window.innerHeight)
                    this.$parent.$refs.context.style.top = (window.innerHeight - rect.height - 25 ) + 'px';
                else
                    this.$parent.$refs.context.style.top = (iconRect.top - 10) + "px";

                this.$parent.$refs.context.style.left = (iconRect.right + 10) + "px";
                this.$parent.$refs.popper.style.left  = (iconRect.right + 5) + "px";
                this.$parent.$refs.popper.style.top   = (iconRect.top + (iconRect.height / 2) - 5) + "px";

                document.addEventListener('click',this.hideContext);
            });
        },
    },
    render(){
        let rows = [];
        this.grid.elements.forEach((row, ri) => {

        let max = 0;
        this.grid._system.readyHeaderKeys.forEach(key => {
            if(max < row[key][row[key].length - 1].end)
                max = row[key][row[key].length - 1].end
            })

            let columnElement = [];
            this.grid._system.readyHeaderKeys.forEach(column => {
                row[column].forEach((cell, index) => {

                    let endRow = cell.end;
                    if (row[column].length <= 1)
                        endRow = (row[column].length - 1 == index ? max : cell.end);

                    if(column == 'icon'){
                        // Иконка для контекстного меню
                        let child = [];
                        if(('context' in row && row.context.length) || this.grid.context.length){
                            child.push(h(resolveComponent('el-icon'), {
                                onClick : $event => {
                                        $event.stopPropagation();
                                        this.hideContext()
                                        this.$nextTick(() => {
                                            this.showContext($event,row);
                                        })
                                    }
                                },() => h(resolveComponent('expand'))
                            ))
                        }
                        if(this.grid.config.columnDisplayContext)
                            columnElement.push(h('div',{style : {gridRow : cell.start + '/' + endRow}},child))
                    }else {
                        let func = 'default'
                        if (column in this.grid.wrapperCell || cell.wrapper) {
                            func = column
                            if (cell.wrapper)
                                func = cell.wrapper
                        }

                        columnElement.push(this.grid.wrapperCell[func].call(this.grid, {
                            row: ri,
                            column: column,
                            cell: index,
                            gridRow: cell.start + '/' + endRow
                        }));
                    }

                    if(row[column][index + 1] && endRow < row[column][index + 1].start) {
                        columnElement.push(h('div', {style: {'grid-row': endRow + '/' + row[column][index + 1].start}}));
                    }

                    if(!row[column][index + 1] && endRow < max) {
                        columnElement.push(h('div', {style: {'grid-row': endRow + '/' + max}}));
                    }
                });
            })

            rows.push(h('div', {style : {'grid-template-columns' : this.grid._system.gridTemplateColumns}}, columnElement))
        });

        return h('div', {
            class : ['vdg_contentWrapper', this.name],
            style : {
                width : this.grid._system.scroll+'px',
                'clip-path' : 'inset(0px)',
            }
        }, rows);
    }
}
</script>
