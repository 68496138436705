import normal from "./normal";
import action from "./action";
import {computed, reactive} from "vue";

export default {
    normal : {
        // Блок ответственности за стартовую настройку грида
        config : (data) => {
            if('config' in data)
                data.config = {
                    ...normal.config,
                    ...data.config
                }
            else data.config = normal.config
            return data;
        },
        // Нормализация шапки
        columnHeader : (data) => {
            Object.keys(data.header).forEach((key) => {
                data.header[key] = {
                    ...normal.columnHeader,
                    ...data.header[key]
                }
            });
            Object.keys(data.header)
                .sort((a,b) => data.header[a].sort - data.header[a].sort)
                .forEach((key,i) => {
                    data.header[key].sort += i;
                });
            return data;
        },
        // Нормализация шапки
         columnHeaderIcon : (data) => {
            data.header['icon'] = normal.columnHeaderIcon

            if(!data.config.headerDisplaySetting && !data.config.columnDisplayContext)
                data.header['icon'].show = false
            return data;
        },
        // Блок ответственности за динамику в верстке
        _system : (data) => {
            data._system = normal._system
            return data;
        },
        // Системные функции сетки
        action : (data) => {
            if('action' in data)
                data.$action = {
                    ...action,
                    ...data.action
                }
            else data.$action = action
            return data;
        },
        // Массив строк сетки
        elements : (data) => {
            data.elements = [];
            return data;
        },
        // Фуекции для обертки ячейки
        wrapperCell : (data) => {
            if('wrapperCell' in data)
                data.wrapperCell = {
                    ...normal.wrapperCell,
                    ...data.wrapperCell
                }
            else data.wrapperCell = normal.wrapperCell

            return data;
        },
        // Фуекции для обертки ячейки
        context : (data) => {
            if(!('context' in data))
                data.context = normal.context
            return data;
        },
        // Фуекции для обертки ячейки
        rowContext : (data) => {
            if(!('context' in data))
                data.rowContext = normal.rowContext
            return data;
        },
        // Настройки постраничной навигации
        pagination : (data) => {
            if(!('pagination' in data))
                data.pagination = normal.pagination
            else{
                if('top' in data.pagination)
                    data.pagination.top = {
                        ...normal.pagination.top,
                        ...data.pagination.top,
                        use : true
                    }
                else data.pagination.top = normal.pagination.top;

                if('bottom' in data.pagination)
                    data.pagination.bottom = {
                        ...normal.pagination.bottom,
                        ...data.pagination.bottom,
                        use : true
                    }
                else data.pagination.bottom = normal.pagination.bottom;

                if('default' in data.pagination)
                    data.pagination.default = {
                        ...normal.pagination.default,
                        ...data.pagination.default,
                        use : true
                    }
                else data.pagination.default = normal.pagination.default;
            }
            return data;
        },
        // Настройки фильтра
        filter : (data) => {
            if(!('filter' in data))
                data.filter = normal.filter
            else{
                for (const key in data.filter) {
                    data.filter[key] = {
                        ...normal.filterType[data.filter[key].type],
                        ...data.filter[key]
                    }
                }
            }
            return data;
        },
        // Настройки профилей фильтра
        filterProfile : (data) => {
            if(!('filterProfile' in data)) {
                data.filterProfile = normal.filterProfile
                data.filterProfileClone = JSON.parse(JSON.stringify(normal.filterProfile))
            }else{
                data.filterProfile = {
                    ...data.filterProfile,
                    ...normal.filterProfile
                }
            }
            return data;
        },
        // Состояние сетки
        condition : (data) => {
            data.condition = JSON.parse(JSON.stringify(normal.condition))
            return data;
        },
        // Фуекции для ....
        remoteQuery : (data) => {
            if(!('remoteQuery' in data)){
                data.remoteQuery = {};
                if(!('header' in data.remoteQuery)){
                    data.remoteQuery.header = normal.remoteQuery.header
                }
                if(!('body' in data.remoteQuery)){
                    data.remoteQuery.body = normal.remoteQuery.body
                }
            }
            return data;
        },
        // Фуекции для ....
        localStorage : (name, data) => {
            let thisStorage = {
                system      : {
                    getSetting : data.config.remoteSetting ? 'remote' : 'local',
                    getElement : data.config.remoteElement ? 'remote' : 'local',
                    saved : false
                },
                header      : data.header,
                filter      : data.filterProfile,
                pagination  : { pageSize : data.condition.pagination.pageSize },
                sort        : { direction : 'asc', field : ''}
            }
            let localStorageData = localStorage.getItem('vdg')

            if(!localStorageData) {
                localStorageData = {}
                localStorageData[name] = thisStorage
            }else {
                localStorageData = JSON.parse(localStorageData)
                if (!(name in localStorageData))
                    localStorageData[name] = thisStorage
            }

            localStorage.setItem('vdg',JSON.stringify(localStorageData))

            data.localStorage = {
                get : function(grid, sector){
                    let localStorageData = JSON.parse(localStorage.getItem('vdg'))
                    return localStorageData[grid][sector];
                },
                set : function(grid, sector, value){
                    let localStorageData = JSON.parse(localStorage.getItem('vdg'))
                    localStorageData[grid][sector] = value;
                    localStorage.setItem('vdg',JSON.stringify(localStorageData))
                }
            }
            return data;
        },

        // Фуекции для ....
        queue : (data) => {
            data.queue = {
                executed : false,
                data : [],
                func : [],
                add : function(grid, functionName = [], data = {}){
                    this.func.push(functionName)
                    this.data.push(data)
                    if(!this.executed){
                        this.executed = true;
                        this.exec(grid);
                    }
                },
                exec : function(grid){
                    let func = this.func.shift()
                    let data = this.data.shift()

                    let obj =  grid.$action[func.shift()];
                    func.forEach(el => {
                        obj = obj[el];
                    })

                    obj(grid, data).then(() => {
                        if(this.func.length)
                            this.exec(grid);
                        else
                            console.log('final');
                    })
                },
            };

            return data;
        },
    },
    // вычисляемые свойства для отрисовки
    computed : (grid, name) => {
        grid._system.headerKeys = computed(() => Object.keys(grid.header).filter(key => !grid.header[key].exclude));
        grid._system.readyHeaderKeys = computed(() => grid._system.headerKeys
                .filter(key => !!grid.header[key].show)
                .sort((a, b) => grid.header[a].sort - grid.header[b].sort)
        );

        grid._system.gridTemplateColumns = computed(() => grid._system.readyHeaderKeys.map(key => grid.header[key].width).join('px ') + 'px');
        let sort = grid.localStorage.get(name,'sort');

        if(sort.field == '')
            grid._system.sortable = reactive({
                direction : "asc",
                field : grid._system.headerKeys.filter(key => grid.header[key].sortable)[0],
            })
        else
            grid._system.sortable = reactive(sort)

        grid.localStorage.set(name,'sort',grid._system.sortable)

        grid._system.scroll =  computed(() => (
            grid._system.readyHeaderKeys.reduce((prev, current) => prev + grid.header[current].width, 0) +
            (grid._system.readyHeaderKeys.length - 1) * 10
        ));
        grid._system.context = null;
        grid._system.columnKey = Date.now();
        grid.condition.pagination.pageSize = grid.pagination.default.pageSize;
    }
}
