import { provide, reactive} from "vue";
import system from "./system";

export default function(name,data) {

    data = system.normal.config(data);
    data = system.normal.pagination(data);
    data = system.normal.filter(data);
    data = system.normal.filterProfile(data);
    data = system.normal.condition(data);
    data = system.normal.columnHeader(data);
    data = system.normal.columnHeaderIcon(data);
    data = system.normal.remoteQuery(data);
    data = system.normal._system(data);
    data = system.normal.action(data);
    data = system.normal.wrapperCell(data);
    data = system.normal.context(data);
    data = system.normal.rowContext(data);
    data = system.normal.elements(data);
    data = system.normal.localStorage(name,data);
    data = system.normal.queue(data);

    data.header     = reactive(data.header)
    data._system    = reactive(data._system)
    data.elements   = reactive(data.elements)
    data.condition  = reactive(data.condition)
    data.filter     = reactive(data.filter)

    system.computed(data, name);
    provide(name, data);

    return data;

};
