<script>
import {inject, h, Fragment, resolveComponent} from "vue";

export default {
    name : 'HeaderRow',
    props : ['name'],
    setup(props){
        let grid = inject(props.name);
        return {grid}
    },
    render() {
        return h(Fragment,null, [
            // Формирование шапки обертка
            h('div', {
                    ref : 'rowHeader',
                    class : ['vdg_rowHeader', this.name],
                    style : {
                        'grid-template-columns' : this.grid._system.gridTemplateColumns,
                        width : this.grid._system.scroll + 'px'
                    }
                },
                // Формирование колонок шапки из видимых сортированых элементов
                this.grid._system.readyHeaderKeys.map(key => {
                    return h('div', {
                        class : [key],
                        ref : 'drag_'+ key,
                        onMousedown : $event => this.onDragStart($event,key),
                    }, this.column(key))
                })
            ),
            this.scrollbar(),
            this.dialog()
        ]);
    },
    mounted() {
        // обработка изменения размера и перемещения колонки
        document.addEventListener('mousemove', this.mouseMove)
        // Старт изменения размера и перемещения колонки
        document.addEventListener('mouseup', this.mouseUp)
    },
    unmounted() {
        document.removeEventListener('mousemove', this.mouseMove)
        document.removeEventListener('mouseup', this.mouseUp)
    },
    data : () => {
        return{
            resize        : { start : 0, width : 0, key : null },
            drag          : { back : null, this : null, next : null },
            dialogShow    : false
        }
    },
    methods : {
        // Отрисовка колонок шапки
        column : function(key){
            if(key === 'icon'){
                return [
                    h('span'),
                    h(resolveComponent('el-icon'),{
                            onClick : () => {this.dialogShow = true}
                        }, () => this.grid.config.headerDisplaySetting ? h(resolveComponent('tools')) : ''),
                    h('div')
                ]
            }else{
                let caret = null, color = '#a8abb2';
                if(this.grid._system.sortable.field == key ){
                    caret = 'CaretTop';
                    color = '#000000'
                    if(this.grid._system.sortable.direction == 'desc')
                        caret = 'CaretBottom';
                }else if(this.grid.header[key].sortable) caret = 'CaretTop';

                return [
                    h(resolveComponent('el-tooltip'), {
                        disabled : this.tooltip(key),
                        placement : "bottom",
                        effect : "dark"
                    },{
                        content : () => h('span', {ref : 'tooltip_' + key}, this.grid.header[key].name),
                        default : () => h('span', {
                            onClick : () => {
                                if(caret) {
                                    if (this.grid._system.sortable.field == key)
                                        this.grid._system.sortable.direction = this.grid._system.sortable.direction == 'asc' ? 'desc' : 'asc';
                                    else {
                                        this.grid._system.sortable.direction = 'asc'
                                        this.grid._system.sortable.field = key;
                                    }
                                    this.grid.$action.updateCondition(this.grid, 'sortable', {
                                        key: this.grid._system.sortable.field,
                                        value: this.grid._system.sortable.direction
                                    });
                                }
                            },
                        },this.grid.header[key].name)
                    }),
                    h(resolveComponent('el-icon'),() => caret ? h(resolveComponent(caret), {
                        style : {color : color},
                        onClick: () => {
                            if(this.grid._system.sortable.field == key)
                                this.grid._system.sortable.direction = this.grid._system.sortable.direction == 'asc' ? 'desc' : 'asc';
                            else {
                                this.grid._system.sortable.direction = 'asc'
                                this.grid._system.sortable.field = key;
                            }
                            this.grid.$action.updateCondition(this.grid, 'sortable', {
                                key : this.grid._system.sortable.field,
                                value : this.grid._system.sortable.direction
                            });
                        }
                    }) : ''),
                    h('div', { onMousedown: $event => this.onResizeStart($event,key) })
                ]
            }
        },
        // Прерывания сортировки и изменения ширины колонок
        mouseUp : function(){
            //Прерывание измененеия порядка колонок
            if(this.drag.this || false === this.drag.this){
                this.drag.this = null;
                if(this.drag.node)
                    this.drag.node.remove();
            }
            //Прерывани измененеия ширины колонки
            if(this.resize.key){
                this.$refs.scroll.update();
                this.grid.$action.updateCondition(this.grid, 'resizable', {
                    key : this.resize.key,
                    value : this.grid.header[this.resize.key].width
                });
                this.resize = {start : 0, width : 0, key : null};
            }
        },
        // Обработка сортировки и изменения ширины колонок
        mouseMove : function(e){
            // Расчет ширины колонки
            if(this.resize.key){
                let width = this.resize.width + (e.clientX - this.resize.start);
                this.grid.header[this.resize.key].width = width < 80 ? 80 : width;
                let content = document.querySelector('.vdg_contentWrapper.' + this.name);
                if(content) {
                    this.$nextTick(() => {
                        content.style.clipPath = 'inset(0 ' + (this.grid._system.scroll - this.$refs.rowHeader.clientWidth) + 'px 0 ' + 0 + 'px)';
                        content.style.width = this.grid._system.scroll + 'px';
                    })
                }
            }
            // Просчет смещения колонок
            if(this.drag.this && 'key' in this.drag.this){
                let dragLeft = e.clientX - (this.grid.header[this.drag.this.key].width / 2),
                    updateKey = null;

                this.drag.node.style.left = dragLeft + 'px';

                if(this.drag.back && (this.drag.back.rect.x + (this.drag.back.rect.width / 2)) > e.clientX)
                    updateKey = 'back';

                if(this.drag.next && (this.drag.next.rect.x + (this.drag.next.rect.width / 2)) < e.clientX)
                    updateKey = 'next';

                if(updateKey){
                    let sort = this.grid.header[this.drag.this.key].sort;
                    this.grid.header[this.drag.this.key].sort = this.grid.header[this.drag[updateKey].key].sort;
                    this.grid.header[this.drag[updateKey].key].sort = sort;
                    this.grid.$action.updateCondition(this.grid, 'draggable', [{
                            key: this.drag.this.key,
                            sort: this.grid.header[this.drag[updateKey].key].sort
                        },{
                            key: this.drag[updateKey].key,
                            sort: this.grid.header[this.drag.this.key].sort
                        }
                    ]);
                    this.dragInit(this.drag.this.key);
                }
            }
        },
        // Диалоговое окно для отключения показа элементов
        dialog : function(){
            return h(resolveComponent('el-dialog'), {
                    class : 'vdg_showFieldsModal',
                    modelValue : this.dialogShow,
                    'onUpdate:modelValue': value => {this.dialogShow = value},
                    title : "Настройка списка",
                    width : '30%'
                }, () => h(resolveComponent('el-scrollbar'),{
                        height : '400px',
                        class : 'vdg_showFieldsModal_scroll'
                    }, () => this.grid._system.headerKeys.filter(el => el !== 'icon').map(el => h(resolveComponent('el-checkbox'), {
                            class : 'vdg_showFieldsModal_item',
                            'model-value' : this.grid.header[el].show,
                            'onUpdate:modelValue': value => {
                                this.grid.header[el].show = value;
                                this.$nextTick(() => {
                                    this.$refs.scroll.update();
                                    this.grid.$action.updateCondition(this.grid, 'visible', {key : el, value : value});
                                });
                            },
                            label : this.grid.header[el].name,
                            size : 'large'
                        },'')
                    )
                )
            )
        },
        // Горизонтальная прокрутка сетки
        scrollbar : function(){
            return h(resolveComponent('el-scrollbar'),{
                class : 'vdg_rowHeader_scrollbar',
                ref : 'scroll',
                always : true,
                onScroll : $e => this.scroll($e)
            }, () => h('div',{style : {width : this.grid._system.scroll + 'px', height : '10px'}}))
        },
        // Старт изменения ширины колонки
        onResizeStart (e,key){
            e.stopPropagation();
            this.resize = {start : e.clientX, width : this.grid.header[key].width, key : key};
        },
        // Обработка данных для сортировки колонок
        dragInit (key){
            let start = this.grid._system.readyHeaderKeys.indexOf(key),
                back = start - 1,
                next = start + 1;

            this.drag.this = {
                key : this.grid._system.readyHeaderKeys[start],
                rect : this.$refs['drag_' + key].getBoundingClientRect()
            };

            if(start === 0 || this.grid._system.readyHeaderKeys[back] == 'icon')
                this.drag.back = null
            else
                this.drag.back = {
                    key : this.grid._system.readyHeaderKeys[back],
                    rect : this.$refs['drag_' + this.grid._system.readyHeaderKeys[back]].getBoundingClientRect()
                }

            if(next === this.grid._system.readyHeaderKeys.length)
                this.drag.next = null
            else
                this.drag.next = {
                    key : this.grid._system.readyHeaderKeys[next],
                    rect : this.$refs['drag_' + this.grid._system.readyHeaderKeys[next]].getBoundingClientRect()
                }
        },
        // Проверка полностью или частично показывается заголовок шапки
        tooltip(key){
            let flag = true, tooltip = this.$refs['tooltip_' + key];
            if(tooltip && tooltip.clientWidth < tooltip.scrollWidth)
                flag = false
            return flag;
        },
        // Обработка боковой прокрутки сетки
        scroll({scrollLeft}){
            this.$refs.rowHeader.scrollLeft = scrollLeft;
            let content = document.querySelector('.vdg_contentWrapper.' + this.name);
            if(content) {
                content.style.clipPath = 'inset(0 ' + (this.grid._system.scroll - this.$refs.rowHeader.clientWidth - scrollLeft /*+ 15*/) + 'px 0 ' + scrollLeft + 'px)';
                content.style.left = -scrollLeft + 'px';
                content.style.width = this.grid._system.scroll + 'px';
            }
        },
        // Старт сортировки колонок сетки
        onDragStart(e,key){
            if(key !== 'icon' || e.which === 1) {

                this.drag.this = false;

                setTimeout(() => {
                    if (this.drag.this !== null) {
                        let node = document.createElement('div');
                        this.dragInit(key);

                        node.classList.add('vdg_rowHeader_dragActiveElement');
                        node.append(this.grid.header[key].name)
                        node.style.left = (e.clientX - (this.grid.header[key].width / 2)) + 'px';
                        node.style.top = this.drag.this.rect.y + 'px';
                        node.style.width = this.grid.header[key].width + 'px';
                        this.drag.node = node;
                        document.body.append(node);
                    }
                }, 200)
            }
        },
    }
}
</script>
