import {createRouter, createWebHistory } from 'vue-router'

import Root from '../pages/root'
import NotFound from '../pages/root/notfound'

import CityList from '../pages/city/list'
import CityAdd from '../pages/city/add'

import OrganizationList from '../pages/organization/list'
import OrganizationAdd from '../pages/organization/add'
import OrganizationEdit from '../pages/organization/edit'

import UserAdd from '../pages/user/add'
import UserList from '../pages/user/list'
import UserEdit from '../pages/user/edit'
import UserRightList from '../pages/user/right'
import UserGroupList from '../pages/user/group'

import GroupList from '../pages/group/list'
import GroupAdd from '../pages/group/add'
import GroupRightList from '../pages/group/right'

import LicenseList from '../pages/license/list'
import LicenseAdd from '../pages/license/add'

const routes = [
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: NotFound,
    },
    {
        path      : '/',
        component : Root,
        meta     : {
            navbar  : true,
            lvl     : 1,
            key     : "home",
            sort    : 1000,
            name    : 'Главная'
        },
    },
    {
        path      : '/city',
        name       :'city',
        meta     : {
            navbar  : true,
            lvl     : 1,
            key     : "city",
            sort    : 900,
            name    : 'Города',
            right   : 1
        },
        children : [
            {
                path      : '',
                name       :'city',
                component : CityList,
                meta     : {
                    navbar  : false,
                    key     : "city",
                }

            },{
                path      : 'add',
                component : CityAdd,
                meta     : {
                    navbar  : false,
                    key     : "city",
                }
            }
        ]
    },
    {
        path      : '/organization',
        name      : 'organization',
        meta     : {
            navbar  : true,
            lvl     : 1,
            key     : "organization",
            sort    : 800,
            name    : 'Организации',
            right   : 7
        },
        children : [
            {
                path      : '',
                name      : 'organization',
                component : OrganizationList,
                meta     : {
                    navbar  : false,
                    lvl     : 2,
                    key     : "organization",
                    sort    : 800,
                    name    : 'Список'
                },
            },{
                path      : 'add',
                component : OrganizationAdd,
                meta     : {
                    navbar  : false,
                    key     : "organization",
                },
            },{
                path      : 'edit/:element',
                component : OrganizationEdit,
                meta     : {
                    navbar  : false,
                    key     : "organization",
                },
            }
        ]
    },
    {
        path      : '/user',
        name      : 'user',
        meta     : {
            navbar  : true,
            lvl     : 1,
            key     : "user",
            sort    : 600,
            name    : 'Пользователи',
            right   : 36
        },
        children : [
            {
                path      : '',
                name      : 'user',
                component : UserList,
                meta     : {
                    navbar  : false,
                    key     : "user",
                },
            },{
                path      : 'add',
                component : UserAdd,
                meta     : {
                    navbar  : false,
                    key     : "user",
                },
            },{
                path      : ':element/right',
                component : UserRightList,
                meta     : {
                    navbar  : false,
                    key     : "user",
                },
            },{
                path      : ':element/group',
                component : UserGroupList,
                meta     : {
                    navbar  : false,
                    key     : "user",
                },
            },{
                path      : 'edit/:element',
                component : UserEdit,
                meta     : {
                    navbar  : false,
                    key     : "user",
                },
            }
        ]
    },
    {
        path      : '/license',
        name      : 'license',
        meta     : {
            navbar  : false,
            key     : "license"
        },
        children : [
            {
                path      : ':city_id',
                name      : 'license',
                component : LicenseList,
                props     : true,
                meta      : {
                    navbar  : false,
                    key     : "license",
                },
            },
            {
                path      : ':city_id/add',
                component : LicenseAdd,
                meta     : {
                    navbar  : false,
                    key     : "license",
                }
            }
        ]
    },
    {
        path      : '/group',
        name      : 'group',
        meta     : {
            navbar  : true,
            lvl     : 1,
            key     : "group",
            sort    : 700,
            name    : 'Группы',
            right   : 26
        },
        children : [
            {
                path      : '',
                name      : 'group',
                component : GroupList,
                meta     : {
                    navbar  : false,
                    key     : "group",
                },
            },{
                path      : 'add',
                component : GroupAdd,
                meta     : {
                    navbar  : false,
                    key     : "group",
                },
            },{
                path      : ':element/right',
                component : GroupRightList,
                meta     : {
                    navbar  : false,
                    key     : "group",
                },
            }
        ]
    }
];



const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  previous : null,
})

router.beforeEach((to, from, next) => {
    router.previous = from.name;
    next()
})

export default router
