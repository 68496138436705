import {ElLoading, ElNotification} from "element-plus";
import {reactive} from "vue";

export default {
    extReload : (grid) => {
        grid.$action.loader(grid);
        grid.$action.reload(grid).then(() => {
            grid.$action.loader(grid);
        });
    },
    loader : (grid) => {
        if(!grid._system.loading)
            grid._system.loading = ElLoading.service({
                target : "#grid-wrap-" + grid.name,
                text : "Загрузка данных...",
                lock : false
            })
        else{
            grid._system.loading.close();
            grid._system.loading = null;
        }
    },
    notify : (message) => {
        ElNotification({
            title: message.title,
            message: message.text,
            type: message.type
        })
    },
    queueFunction : {
        loader : (grid) => {
            return new Promise(next => {
                grid.$action.loader(grid);
                next()
            })
        },
        initDefaultProfile : (grid) => {
            return new Promise(next => {
                grid.$action.initDefaultProfile(grid);
                next()
            })
        },
    },
    updateCondition : (grid, type, data) => {
        let store = grid.localStorage.get(grid.name,'system')
        switch (type){
            //header
            case 'resizable': case 'visible':
                grid.$action[store.getSetting].header.update(grid, [data.key])
                break;
            case 'draggable':
                grid.$action[store.getSetting].header.update(grid, data.map(el => el.key))
                break;
            // filter
            case 'changeValueFilterField':
                grid.$action[store.getSetting].filter.changeValueFilterField(grid)
                break;
            case 'hideField':
                grid.$action[store.getSetting].filter.hideField(grid, data)
                break;
            case 'showField':
                grid.$action[store.getSetting].filter.showField(grid, data)
                break;
            case 'createProfile':
                grid.$action[store.getSetting].filter.createProfile(grid)
                break;
            case 'updateProfile':
                grid.$action[store.getSetting].filter.updateProfile(grid)
                break;
            case 'removeProfile':
                grid.$action[store.getSetting].filter.removeProfile(grid, data)
                break;
            case 'changePage':
                grid.condition.pagination.page = data;
                if(grid.router && grid.route){
                    grid.router.push({
                        path : grid.route.path,
                        query : {page : data}
                    }).then(() => {
                        grid.$action.extReload(grid);
                    })
                }else{
                    grid.$action.extReload(grid);
                }
                break;
            case 'changePaginationSize':
                grid.condition.pagination.page = 1;
                grid.condition.pagination.pageSize = data;
                grid.$action[store.getSetting].pagination.update(grid, data).then(() => {
                    if(grid.router && grid.route)
                        grid.router.push({path : grid.route.path}).then(() => {
                            grid.$action.extReload(grid);
                        })
                    else
                        grid.$action.extReload(grid);
                })
                break;
            case 'sortable':
                grid.$action[store.getElement].sortable(grid, data);
                break
            case 'filterApply':
                grid.condition.filter.use = true;
                grid.condition.pagination.page = 1;
                if(grid.router && grid.route)
                    grid.router.push({path : grid.route.path}).then(() => {
                        grid.$action.extReload(grid);
                    })
                else
                    grid.$action.extReload(grid);
                break;
            case 'filterCancel':
                grid.condition.filter.use = false;
                grid.condition.pagination.page = 1;
                if(grid.router && grid.route)
                    grid.router.push({path : grid.route.path}).then(() => {
                        grid.$action.extReload(grid);
                    })
                else
                    grid.$action.extReload(grid);
                break
            default :
                console.log('Неизвестное событие ' + type, data)
        }
        console.log(grid, type, data)
    },
    insert : (grid,elements) => {
        /* r - row
        * rk - rowKey
        * c - column
        * ck - columnKey
        * fr - finalRow
        * fc - finalColumn
        * chr - childrenRow
        * i  - startIndex
        * hl - highLvl
        * */
        //Рекурсивный перебор дерева элементов
        let extractChild = (grid, r, i = 0, hl = false) => {
            let fr = {};
            Object.keys(r).forEach(rk => {
                // если элемент не показывается или если нет дочерних не ведем расчет
                if (!(rk in grid.header)) return;

                fr[rk] = []; // Массив рядов колонки
                r[rk].forEach((c, ck) => {
                    let fc = {};
                    fc.value = c.value
                    fc.wrapper = 'wrapper' in c ? c.wrapper : null
                    fc.meta = 'meta' in c ? c.meta : {}
                    // Определяем начало и конец ряда колонки
                    // Для первого уровня по предидущему ряду
                    // Для вложеных по передоваемому индексу
                    if (hl) {
                        fc.start = fr[rk][ck - 1] ? fr[rk][ck - 1].end : ck + 1
                        fc.end = fr[rk][ck - 1] ? fr[rk][ck - 1].end + 1 : ck + 2
                    } else {
                        fc.start = i
                        fc.end = i + 1
                    }

                    // Получение и приведение к общему виду вложеных элементов
                    if ('child' in c) {
                        let chr = extractChild(grid, c.child, fc.start);
                        Object.keys(chr).map(key => {
                            fr[key] = key in fr ? fr[key].concat(chr[key]) : chr[key];
                            if (chr[key][chr[key].length - 1].end > fc.end)
                                fc.end = chr[key][chr[key].length - 1].end;
                        })
                    }

                    fr[rk].push(fc)
                    i = fc.end;
                })

                if (!fr[rk].length)
                    fr[rk].push({value: '', start: hl ? 1 : i, end: i + 1})

                if (hl) {
                    fr.icon = [{value: '', start: 1, end: 2}]

                    if ('context' in r) fr.context = r.context.map(key => grid.rowContext[key]);
                    else fr.context = grid.context
                }
            })
            return fr;
        }

        grid.elements = reactive(elements.map(r => {
            return extractChild(grid, r, 0, true);
        }));

        grid._system.columnKey = Date.now()
    },

    init : (grid) => {

        grid.condition.pagination.page = 1;
        let store = grid.localStorage.get(grid.name,'system')

        grid.queue.add(grid,[store.getSetting,'header','check'])
        grid.queue.add(grid,[store.getSetting,'header','init'])
        grid.queue.add(grid,[store.getSetting,'header','get'])
        grid.queue.add(grid,['queueFunction','initDefaultProfile'])
        grid.queue.add(grid,[store.getSetting,'filter','init'])
        grid.queue.add(grid,[store.getSetting,'filter','get'])
        grid.queue.add(grid,[store.getSetting,'pagination','init'])
        grid.queue.add(grid,[store.getElement,'element','get'])
        grid.queue.add(grid,['queueFunction','loader'])

    },
    reload : (grid) => {
        let store = grid.localStorage.get(grid.name,'system')
        return grid.$action[store.getElement].element.get(grid)
    },
    remote : {
        filter : {
            // Сохраняем поля на сервере
            init : (grid) => {
                return new Promise((next,err) => {
                    let sys = grid.localStorage.get(grid.name,'system')
                    if(!sys.saved) {
                        grid.$action.fetch(grid, grid.config.remoteUrlSetting + '/grid/profile/add',{
                            grid : grid.name,
                            profile : grid.filterProfile[1]
                        }).then(() =>  next()).catch(() => {
                            err('Queue error : function "filter:init" not response')
                        })
                    }else next();
                });
            },
            // Запрашиваем профили на сервере
            get : (grid) => {
                return new Promise((next,err) => {
                    grid.$action.fetch(grid, grid.config.remoteUrlSetting + '/grid/profile/get',{
                        grid : grid.name
                    }).then(res =>  {
                        grid.filterProfile = reactive(res.profiles);
                        grid.filterProfileClone = JSON.parse(JSON.stringify(res.profiles))
                        next();
                    }).catch(() => {
                        err('Queue error : function "filter:get" not response')
                    })
                });
            },
            changeValueFilterField : (grid) => {
                if(grid.condition.filter.profile != 1){
                    if(!!grid.$action.diff(grid).length)
                        grid.condition.filter.updateProfile = true;
                    else
                        grid.condition.filter.updateProfile = false;
                }
            },
            hideField : (grid, data) => {
                if(grid.condition.filter.profile == 1) {
                    let store = grid.localStorage.get(grid.name,'system')
                    let index = grid.filterProfile[grid.condition.filter.profile].fields.findIndex(f => f.key == data.key)
                    grid.filterProfile[grid.condition.filter.profile].fields[index] = data;
                    grid.$action[store.getSetting].filter.save(grid, grid.filterProfile[grid.condition.filter.profile]);
                }else if(grid.condition.filter.profile != 1){
                    if(!!grid.$action.diff(grid).length)
                        grid.condition.filter.updateProfile = true;
                    else
                        grid.condition.filter.updateProfile = false;
                }
            },
            showField : (grid, data) => {
                if(grid.condition.filter.profile == 1) {
                    let store = grid.localStorage.get(grid.name,'system')
                    let index = grid.filterProfile[grid.condition.filter.profile].fields.findIndex(f => f.key == data.key)
                    grid.filterProfile[grid.condition.filter.profile].fields[index] = data;
                    grid.$action[store.getSetting].filter.save(grid, grid.filterProfile[grid.condition.filter.profile]);
                }else if(grid.condition.filter.profile != 1){
                    if(!!grid.$action.diff(grid).length)
                        grid.condition.filter.updateProfile = true;
                    else
                        grid.condition.filter.updateProfile = false;
                }
            },
            createProfile : (grid) => {
                let store = grid.localStorage.get(grid.name,'system')
                grid.$action[store.getSetting].filter.save(grid, grid.filterProfile[grid.condition.filter.profile]);
            },
            updateProfile : (grid) => {
                let store = grid.localStorage.get(grid.name,'system')
                if(grid.condition.filter.profile != 1) {
                    grid.$action[store.getSetting].filter.save(grid, grid.filterProfile[grid.condition.filter.profile]);
                    grid.condition.filter.updateProfile = false;
                }
            },
            removeProfile : (grid,data) => {
                return new Promise((res,rej) => {
                        if(grid.condition.filter.profile != 1) {
                            delete grid.filterProfile[data];
                            grid.filterProfileClone = JSON.parse(JSON.stringify(grid.filterProfile));

                            grid.$action.fetch(grid, grid.config.remoteUrlSetting + '/grid/profile/remove',{
                                grid : grid.name,
                                profile : data
                            }).then(() => {
                                res();
                            }).catch(() => {
                                grid.$action.notify({
                                    title   : 'Возникла проблема!',
                                    text    : 'Не удалось удалить профиль',
                                    type    : 'error'
                                })
                                rej()
                            })
                        }else rej('Попытка удалить профиль "По умолчанию"')
                })
            },
            save : (grid,store) => {
                grid.$action.fetch(grid, grid.config.remoteUrlSetting + '/grid/profile/update',{
                    grid : grid.name,
                    profile : store
                }).catch(() => {
                    grid.$action.notify({
                        title   : 'Возникла проблема!',
                        text    : 'Не удалось сохранить профиль',
                        type    : 'error'
                    })
                })
            }
        },
        header : {
            //Проверяем существуют ли настройки на сервере если нет то добавляем корневую запись
            check : (grid) => {
                return new Promise((next,err) => {
                    grid.$action.fetch(grid, grid.config.remoteUrlSetting + '/grid/check',{
                        grid : grid.name
                    }).then(res => {
                        let sys = grid.localStorage.get(grid.name,'system')
                        sys.saved = res.check;
                        grid.localStorage.set(grid.name,'system',sys)
                        next();
                    }).catch(() => {
                        err('Queue error : function "header:check" not response')
                    })
                })
            },
            // Сохраняем поля на сервере
            init : (grid) => {
                return new Promise((next,err) => {
                    let sys = grid.localStorage.get(grid.name,'system')
                    if(!sys.saved) {
                        let header = [];
                        Object.keys(grid.header).forEach(key => {
                            header.push({
                                key: key,
                                show: grid.header[key].show,
                                sort: grid.header[key].sort,
                                width: grid.header[key].width
                            })
                        })
                        grid.$action.fetch(grid, grid.config.remoteUrlSetting + '/grid/header/add', {
                            grid: grid.name,
                            header: header
                        }).then(() => {
                            next();
                        }).catch(() => {
                            err('Queue error : function "header:save" not response')
                        })
                    }else next();
                })
            },
            // Получаем шапку с сервере
            get : (grid) => {
                return new Promise((next,err) => {
                    grid.$action.fetch(grid, grid.config.remoteUrlSetting + '/grid/header/get', {
                        grid: grid.name
                    }).then(res => {
                        grid.$action.initBeforeHeader(grid);
                        Object.keys(grid.header).forEach(key => {
                            grid.header[key] = {
                                ...grid.header[key],
                                ...res.header[key]
                            }
                        })
                        next();
                    }).catch(() => {
                        err('Queue error : function "header:get" not response')
                    })
                })
            },
            // Изменить шапку на сервере
            update : (grid, keys) => {
                return new Promise((next,err) => {
                        let header = [];
                        keys.forEach(key => {
                            header.push({
                                key     : key,
                                show    : grid.header[key].show,
                                sort    : grid.header[key].sort,
                                width   : grid.header[key].width
                            })
                        })
                        grid.$action.fetch(grid, grid.config.remoteUrlSetting + '/grid/header/update',{
                            grid : grid.name,
                            header : header
                        }).then(() => {
                            next();
                        }).catch(() => {
                            err('Event error : function "header:update" not response')
                        })
                })
            }
        },
        element : {
            get : (grid) => {
                return new Promise((next,rej) => {
                    let filter = {};
                    if(grid.condition.filter.use){
                        grid.filterProfile[grid.condition.filter.profile].fields.filter(field => field.show).filter(field => {
                            switch (field.type){
                                case 'date':case 'number':
                                    if(field.min || field.max)
                                        return true
                                    break;
                                case 'list': case 'searchList':
                                    if(field.value instanceof Array && field.value.length > 0 || field.value != '')
                                        return true
                                    break;
                                default: return false
                            }
                        }).forEach(field => {
                            switch (field.type){
                                case 'date':case 'number':
                                    filter[field.key] = {
                                        operation : field.operation,
                                        min : field.min,
                                        max : field.max,
                                        type : field.type
                                    }
                                    break;
                                case 'list': case 'searchList':
                                    filter[field.key] = {
                                        value : field.value,
                                        type : field.type
                                    }
                                    break;
                            }
                        })

                    }

                    grid.$action.fetch(grid, grid.config.remoteUrlElement, {
                        pagination : {
                            limit : grid.condition.pagination.pageSize,
                            offset : grid.condition.pagination.page
                        },
                        filter : filter,
                        sort : grid._system.sortable
                    }).then(res => {
                        grid.condition.pagination.total = Number(res.total)
                        grid.$action.insert(grid, res.elements)
                        next();
                    }).catch(() => {
                        grid.$action.notify({
                            title   : 'Возникла проблема!',
                            text    : 'Не удалось загрузить элементы',
                            type    : 'error'
                        })
                        rej();
                    })
                })
            }
        },
        pagination : {
            init : (grid) => {
                return new Promise(next => {

                    let store = grid.localStorage.get(grid.name,'system')
                    grid.condition.pagination.pageSize = grid.localStorage.get(grid.name,'pagination').pageSize

                    if(grid.router && grid.route){
                        if(store.getElement == 'local') {
                            if ('page' in grid.route.query) {
                                if (grid.route.query.page > 0 && grid.route.query.page <= Math.ceil(grid.row.length / grid.condition.pagination.pageSize))
                                    grid.condition.pagination.page = Number(grid.route.query.page);
                                else {
                                    grid.condition.pagination.page = 1
                                    grid.elements = [];
                                    return;
                                }
                            }
                        }else{
                            grid.condition.pagination.page = Number(grid.route.query.page) || 1;
                        }
                    }
                    next();
                })
            },
            update : (grid,data) => {
                return new Promise(res => {
                    let store = grid.localStorage.get(grid.name,'pagination')
                    store.pageSize = data;
                    grid.localStorage.set(grid.name,'pagination',store)
                    res()
                })
            }
        },
        sortable : (grid,data) => {
            grid.localStorage.set(grid.name,'sort',{
                direction : data.value,
                field     : data.key,
            })
            grid.$action.extReload(grid);
        }

    },
    local : {
        filter : {
            init : (grid) => {
                return new Promise((res,rej) => {
                    if(!grid.config.useFilter) res();
                    grid.$action.initDefaultProfile(grid).then(() => {
                        grid.filterProfile = reactive(grid.localStorage.get(grid.name,'filter'));
                        res();
                    }).catch(err => rej(err))
                })
            },
            changeValueFilterField : (grid) => {
                if(grid.condition.filter.profile != 1){
                    if(!!grid.$action.diff(grid).length)
                        grid.condition.filter.updateProfile = true;
                    else
                        grid.condition.filter.updateProfile = false;
                }
            },
            hideField : (grid, data) => {
                if(grid.condition.filter.profile == 1) {
                    let storageProfile = JSON.parse(localStorage.getItem('filterProfile'));
                    let index = storageProfile[grid.name][grid.condition.filter.profile].fields.findIndex(f => f.key == data.key)
                    storageProfile[grid.name][grid.condition.filter.profile].fields[index] = data;
                    grid.$action[grid.condition.store].filter.save(storageProfile);
                }else if(grid.condition.filter.profile != 1){
                    if(!!grid.$action.diff(grid).length)
                        grid.condition.filter.updateProfile = true;
                    else
                        grid.condition.filter.updateProfile = false;
                }
            },
            showField : (grid, data) => {
                if(grid.condition.filter.profile == 1) {
                    let storageProfile = JSON.parse(localStorage.getItem('filterProfile'));
                    let index = storageProfile[grid.name][grid.condition.filter.profile].fields.findIndex(f => f.key == data.key)
                    storageProfile[grid.name][grid.condition.filter.profile].fields[index] = data;
                    grid.$action[grid.condition.store].filter.save(storageProfile);
                }else if(grid.condition.filter.profile != 1){
                    if(!!grid.$action.diff(grid).length)
                        grid.condition.filter.updateProfile = true;
                    else
                        grid.condition.filter.updateProfile = false;
                }
            },
            createProfile : (grid) => {
                let storageProfile = JSON.parse(localStorage.getItem('filterProfile'));
                storageProfile[grid.name][grid.condition.filter.profile] = grid.filterProfile[grid.condition.filter.profile];
                grid.$action[grid.condition.store].filter.save(storageProfile);
            },
            updateProfile : (grid) => {
                if(grid.condition.filter.profile != 1) {
                    let storageProfile = JSON.parse(localStorage.getItem('filterProfile'));
                    storageProfile[grid.name][grid.condition.filter.profile] = grid.filterProfile[grid.condition.filter.profile];
                    grid.$action[grid.condition.store].filter.save(storageProfile);
                    grid.condition.filter.updateProfile = false;
                }
            },
            removeProfile : (grid,data) => {
                return new Promise((res,rej) => {
                    try{
                        if(grid.condition.filter.profile != 1) {
                            let storageProfile = JSON.parse(localStorage.getItem('filterProfile'));

                            delete storageProfile[grid.name][data];
                            delete grid.filterProfile[data];

                            grid.filterProfileClone = JSON.parse(JSON.stringify(storageProfile[grid.name]))

                            localStorage.setItem('filterProfile', JSON.stringify(storageProfile))
                            res();
                        }else rej('Попытка удалить профиль "По умолчанию"')
                    }catch(err){ rej(err) }
                })
            },
            save : (store) => {
                localStorage.setItem('filterProfile',JSON.stringify(store))
            }
        },
        header : {
            check : (grid) => {
                return Promise.resolve();
            },
            save : (grid) => {
                return Promise.resolve();
            },
            init : (grid) => {
                return new Promise(res => {
                    grid.$action.initBeforeHeader(grid);
                    grid.header = reactive(grid.localStorage.get(grid.name,'header'))
                    res();
                })
            }
        },
        pagination : {
          init : (grid) => {
              return new Promise((res,rej) => {
                  grid.condition.pagination.pageSize = grid.localStorage.get(grid.name,'pagination').pageSize;
                  res();
              })
          },
          update : (grid,data) => {
              return new Promise((res,rej) => {
                  try {
                      let storagePagination = JSON.parse(localStorage.getItem('pagination'));
                      storagePagination[grid.name].pageSize = data;
                      localStorage.setItem('pagination', JSON.stringify(storagePagination))
                      res();
                  }catch(err){ rej(err); }
              })
          }
        },
        element : {
            get : (grid) => {
                return new Promise((next,rej) => {
                    try {
                        grid.condition.pagination.total = grid.row.length
                        let offset = ((+grid.condition.pagination.page - 1) * grid.condition.pagination.pageSize),
                            limit = grid.condition.pagination.pageSize;

                        grid.$action.insert(grid, grid.row.slice(offset,limit + offset))
                        next();
                    }catch(e){ rej(e) }
                })
            }
        }
    },
    initDefaultProfile : (grid) => {
        grid.filterProfile[1].fields = Object.keys(grid.filter).map(key => {
            return {
                ...grid.filter[key],
                key: key
            }
        })
    },
    diff : (grid) => {
        return grid.filterProfile[grid.condition.filter.profile].fields.filter(field => {
            let diff = false;
            grid.filterProfileClone[grid.condition.filter.profile].fields
                .filter(clone => field.key == clone.key)
                .forEach(clone => {
                    Object.keys(clone).forEach(key => {
                        switch (key){
                            case 'show': case 'min': case 'max': case 'operation':
                                if(clone[key] != field[key])
                                    diff = true;
                                break
                            case 'value' :
                                if(field[key] instanceof Array) {
                                    if (
                                        field[key].filter(value => !clone[key].includes(value)).length ||
                                        clone[key].filter(value => !field[key].includes(value)).length
                                    ) diff = true;
                                }else if(clone[key] != field[key]) diff = true;
                                break;
                        }
                    })
                })
            return diff;
        });
    },
    fetch : (grid, url, body ) => {
        return new Promise((next,err) => {
            fetch(url, {
                method: 'post',
                body: JSON.stringify({
                    ...body,
                    ...grid.remoteQuery.body
                }),
                headers: {
                    'Content-Type' : 'application/json;charset=utf-8',
                     ...grid.remoteQuery.header
                }
            })
            .then(res => res.json())
            .then(res => next(res))
            .catch(res => err(res))
        })
    },
    initBeforeHeader : (grid) => {}
}
