<template>
    <License :key="$route.params.city_id"/>
</template>
<script>
import License from './license'

export default {
    name : 'WrapperLicense',
    components : { License }
}

</script>
