<template>
    <template v-if="global.entity.user.checkRight(3)">
        <div class="list-wrapper-header">
            <h4>Добавление города</h4>
        </div>
        <div class="form-wrapper">
            <el-form
                ref="form"
                :model="form"
                :rules="rules"
                label-width="120px"
                status-icon
                label-position="top"
                v-loading="loading"
            >
                <el-form-item label="Наименование города" prop="name">
                    <el-input v-model="form.name" />
                </el-form-item>
                <el-form-item>
                    <el-button class="form-button" type="primary" plain @click="add">Доавить</el-button>
                </el-form-item>
            </el-form>
        </div>
    </template>
    <NotRight v-else />
</template>

<script>
import {inject} from "vue";

export default {
    name: "CityAdd",
    setup : () => {
      const global = inject('global');
      return { global }
    },
    data : function(){
        return {
            loading : false,
            form : {
                name : ''
            },
            rules : {
                name: [{ required: true, message: 'Поле обязательно для заполнения', trigger: 'blur' }]
            }
        }
    },
    methods : {
        add : function(){
            this.$refs.form.validate((valid) => {
                if(valid) {
                    this.loading = true
                    this.global.fetch('/city/add',{
                        name : this.form.name
                    }).then(res => res.json()).then(res => {
                        if(res.user.authorize){
                            this.global.notify.message({
                                title : "Успешно!",
                                text : "Город успешно добавлен",
                                type : 'success'
                            });
                            this.$router.push('/city')
                        }
                    }).catch(() => {
                        this.global.notify.error();
                    }).finally(() => this.loading = false)
                }
            })
        }
    }
}
</script>
