<script>
import Column from "@/vdg/components/column";
import HeaderRow from "@/vdg/components/header";
import Filterable from "@/vdg/components/filterable";
import {h, Teleport, inject, resolveComponent, Transition, Fragment, provide} from "vue";

export default {
  components: {Column,HeaderRow, Filterable},
  name : 'Grid',
  props : ['name'],
  setup(props){
    let grid = inject(props.name);
    grid.name = props.name;

    grid.$action.init(grid)

    return { grid }
  },
  render(){
    let paginationTop = this.grid.pagination.top.use ? h(
        resolveComponent('el-pagination'),
        {
          total         : this.grid.condition.pagination.total,
          layout        : this.grid.pagination.top.layout.join(','),
          pagerCount    : this.grid.pagination.top.pagerCount,
          pageSizes     : this.grid.pagination.top.pageSizes,
          currentPage   : this.grid.condition.pagination.page,
          pageSize      : this.grid.condition.pagination.pageSize,
          'onUpdate:currentPage': value => {
              this.grid.condition.pagination.page = value;
              this.grid.$action.updateCondition(this.grid, 'changePage', value);
          },
          'onUpdate:pageSize': value => {
              this.grid.condition.pagination.size = value;
              this.grid.$action.updateCondition(this.grid, 'changePaginationSize', value);
          }
        }
    ) : null,
    paginationBottom = this.grid.pagination.bottom.use ? h(
        resolveComponent('el-pagination'),
        {
          total         : this.grid.condition.pagination.total,
          layout        : this.grid.pagination.bottom.layout.join(','),
          pagerCount    : this.grid.pagination.bottom.pagerCount,
          pageSizes     : this.grid.pagination.bottom.pageSizes,
          currentPage   : this.grid.condition.pagination.page,
          pageSize      : this.grid.condition.pagination.pageSize,
          'onUpdate:currentPage': value => {
              this.grid.condition.pagination.page = value;
              this.grid.$action.updateCondition(this.grid, 'changePage', value);
          },
          'onUpdate:pageSize': value => {
              this.grid.condition.pagination.size = value;
              this.grid.$action.updateCondition(this.grid, 'changePaginationSize', value);
          }
        }
    ) : null

    return h(
        "div",
        {
            class : ['vdg-wrap'],
            id : "grid-wrap-"+this.name,
        },
        h(Fragment,{
            key : this.grid._system.columnKey
        },[

            h('div',{class : ['vdg_paginationWrapper', this.name]},[
                this.grid.config.useFilter ?  h(Filterable, {name : this.name}) : null,
                paginationTop ? paginationTop : null
              ]
            ),

            h(HeaderRow,{name : this.name}),
          this.grid.elements.length ?
              h(Column,{name : this.name}) :
              h('div',{class : "vdg404"}, "Нет найденых элементов"),

          // Формирует контекстное меню если есть меню элемента берет его если нет общее
          h(Teleport,{to : "body"}, h(Transition, {
                  name : 'fade'
              } , () => this.grid._system.context ? h('div',[
                  h('span', {class : 'main_popper',ref : 'popper'}),
                  h('div', {
                    ref : 'context',
                    class : ['vdg_contextMenuWrapper',this.name],
                  }, this.grid._system.context.context.map(el => {
                        return h('div', {
                               onClick : $event => {
                                 el.function({event : $event,row : this.grid._system.context,grid : this.grid})
                                 this.grid._system.context = null
                               }
                            }, el.text)
                   })
              )] ) : null)
          ),
          h('div',{class : ['vdg_paginationWrapper', this.name]},paginationBottom && this.grid.elements.length ? paginationBottom : null)
        ])
    )
  },
  mounted() {
    this.grid.$action.loader(this.grid);
  }
};

</script>
